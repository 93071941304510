import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {map} from 'rxjs/operators';

import {WSimpleChanges} from '../../types/angular';
import {scrollElementIntoView} from '../../utils/dom/scroll-element-into-view';
import {toggleEvent} from '../../utils/dom/toggle-event';

import {ClickOutsideAction, OverlayInstance, OverlayPlacement, OverlayShowOptions} from './overlay.types';
import {Overlay} from './overlay.service';

const SHOW_HIDE_DELAY = 200;

/**
 * Directive which shows templateRef in the OverlayComponent
 * Can be used as a tooltip, popover and etc.
 */
@Directive({
  selector: '[wOverlay]',
  exportAs: 'overlay',
  standalone: true,
})
export class OverlayDirective implements OnDestroy, OnInit, OnChanges {
  /**
   * Template of the popover, tooltip and etc.
   */
  @Input({alias: 'wOverlay', required: true}) templateRef: TemplateRef<any>;

  /**
   * Possible overlay's placements. 'top', 'top left', 'top right' and etc.
   * First param is a side, second - alignment
   */
  @Input('wOverlayPlacement') placement: string | OverlayPlacement = 'bottom';

  /**
   * If provided, attaches overlay to the element, specified by this input.
   */
  @Input('wOverlayAttachTo') attachTo?: OverlayShowOptions['attachTo'];

  /**
   * Origin element
   */
  @Input('wOverlayOrigin') origin?: HTMLElement;

  /**
   * Event which opens an overlay.
   *
   * `manual` will not add any "open" event handlers and can be used in situations when you need to
   * open an overlay programmatically.
   */
  @Input('wOverlayShowOn') showOn: 'click' | 'hover' | 'mixed' | 'manual' = 'click';

  /**
   * Custom action handler for click outside event
   */
  @Input('wOverlayClickOutsideAction') clickOutsideAction: ClickOutsideAction | false = {
    action: 'close',
    elements: () => [this.element],
  };

  /**
   * Context which should be passed to template
   */
  @Input('wOverlayContext') context?: object;

  /**
   * Should it scroll a page if overlay is partly hidden
   */
  @Input('wOverlayScrollIntoView') shouldScrollIntoView = true;

  @Input('wOverlayMobileView') supportMobileView = true;

  @Input('wOverlayWithBackground') withBackground = false;

  @Input('wOverlayDisabled') disabled = false;

  /**
   * Controls how to handle overlay, if it does not appear fully on screen
   */
  @Input('wOverlayFitStrategy') fitStrategy?: OverlayShowOptions['fitStrategy'];

  /**
   * Event which triggers before the overlay created and open.
   */
  @Output('wOverlayBeforeOpen') beforeOpen = new EventEmitter<OverlayInstance>();

  /**
   * Event which triggers after the overlay created and open.
   */
  @Output('wOverlayAfterOpen') afterOpen = new EventEmitter<OverlayInstance>();

  /**
   * Event which triggers after the overlay was closed.
   */
  @Output('wOverlayAfterClose') afterClose = new EventEmitter<any>();

  /**
   * Opened overlay object.
   */
  private overlayInstance: OverlayInstance | null = null;

  private timer: number | null = null;
  private lastShowOn: 'click' | 'hover' | null = null;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private vcr: ViewContainerRef,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    this.toggleOpenEvents(true);
  }

  ngOnChanges(changes: WSimpleChanges<OverlayDirective>) {
    if (changes.placement && this.overlayInstance) {
      this.hide();
      this.show();
    }
  }

  ngOnDestroy() {
    this.toggleOpenEvents(false);
    this.hide();
  }

  get opened(): boolean {
    return this.overlayInstance !== null;
  }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  get overlayElement(): HTMLElement | undefined {
    return this.overlayInstance?.element;
  }

  toggle() {
    if (this.opened) {
      this.hide();
    } else {
      this.show();
    }
  }

  show = () => {
    if (this.opened || this.disabled) {
      this.stopTimer();

      return;
    }

    this.element.classList.add('overlay-active');

    this.overlayInstance = this.overlay.show(
      {templateRef: this.templateRef, viewContainerRef: this.vcr},
      {
        placement: this.placement,
        origin: this.origin || this.element,
        attachTo: this.attachTo,
        beforeShow: this.beforeOpen,
        props: this.context,
        fitStrategy: this.fitStrategy,
        supportMobileView: this.supportMobileView,
        withBackground: this.withBackground,
        onWindowResize: 'close',
        onEscape: {action: 'close', skipOtherHandlers: false},
        onClickOutside: this.showOn === 'hover' || !this.clickOutsideAction ? undefined : this.clickOutsideAction,
      },
    );
    this.overlayInstance.closed.pipe(map(({result}) => result)).subscribe(this.onHide);
    this.afterOpen.emit(this.overlayInstance);

    if (!TEST && this.shouldScrollIntoView) {
      this.scrollIntoView(this.overlayInstance);
    }

    this.toggleOverlayEvents(true);
  };

  hide = (result?: any) => {
    if (!this.opened) {
      this.stopTimer();
      this.lastShowOn = null;

      return;
    }

    this.overlayInstance!.hide(result);
  };

  showWithDelay = () => {
    if (this.opened) {
      return;
    }

    this.stopTimer();
    this.timer = setTimeout(this.show, SHOW_HIDE_DELAY);
  };

  hideWithDelay = () => {
    if (!this.opened) {
      this.hide();

      return;
    }

    this.stopTimer();
    this.timer = setTimeout(this.hide, SHOW_HIDE_DELAY);
  };

  stopTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  private scrollIntoView({element}: OverlayInstance) {
    if (!element) {
      return;
    }

    const options = {
      margin: 20,
      animate: true,
    };

    const dialogElement = this.element.closest('.w-dialog');

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        // In case when overlay opened in dialog we should scroll dialog's container
        if (dialogElement) {
          scrollElementIntoView(dialogElement, element.firstElementChild, options);
        } else {
          scrollElementIntoView(element.firstElementChild!, options);
        }
      }, 0);
    });
  }

  private toggleOverlayEvents(flag: boolean) {
    if (this.overlayInstance && this.lastShowOn === 'hover') {
      const {element} = this.overlayInstance;

      toggleEvent(element, 'mouseenter', flag, this.show);
      toggleEvent(element, 'mouseleave', flag, this.hideWithDelay);
    }
  }

  private toggleOpenEvents(flag: boolean) {
    const {element} = this;

    switch (this.showOn) {
      case 'hover':
        toggleEvent(element, 'mouseenter', flag, this.handleElementMouseenter);
        toggleEvent(element, 'mouseleave', flag, this.handleElementMouseleave);
        break;
      case 'click':
        toggleEvent(element, 'click', flag, this.handleElementClick);
        break;
      case 'mixed':
        toggleEvent(element, 'mouseenter', flag, this.handleElementMouseenter);
        toggleEvent(element, 'mouseleave', flag, this.handleElementMouseleave);
        toggleEvent(element, 'click', flag, this.handleElementClick);
    }
  }

  private onHide = (result?: any) => {
    this.stopTimer();
    this.toggleOverlayEvents(false);
    this.afterClose.emit(result);
    this.overlayInstance = null;
    this.lastShowOn = null;
    this.element.classList.remove('overlay-active');
  };

  private handleElementMouseenter = () => {
    if (this.lastShowOn === 'hover') {
      this.show();
    } else if (this.lastShowOn !== 'click') {
      this.lastShowOn = 'hover';
      this.showWithDelay();
    }
  };

  private handleElementMouseleave = () => {
    if (this.lastShowOn === 'hover') {
      this.hideWithDelay();
    }
  };

  private handleElementClick = (event: MouseEvent) => {
    event.stopPropagation();

    if (this.opened && this.lastShowOn === 'click') {
      this.hide();
    } else {
      this.toggleOverlayEvents(false);
      this.lastShowOn = 'click';
      this.show();
    }
  };
}
