import {toString} from 'lodash';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {Point} from '@angular/cdk/drag-drop';

import {subscriptions} from '../../services/subscriptions';
import {WSimpleChanges} from '../../types/angular';
import {Overlay} from '../overlay/overlay.service';
import {OverlayInstance, OverlayPlacement} from '../overlay/overlay.types';
import {convertPlacement} from '../overlay/overlay.helpers';
import {OverlayOrigin, PointerOverlayOrigin} from '../overlay/overlay-origin';

import {TooltipComponent, TooltipPlacement} from './tooltip.component';

const DELAY_DEFAULT = 300;
const DELAY_FOR_TEST = 100;

@Directive({
  selector: '[wTooltip]',
  standalone: true,
})
export class TooltipDirective implements OnInit, OnChanges, OnDestroy {
  // Defaults to `top` for `element` origin and `bottom right` for `pointer` origin
  @Input('wTooltipPlacement') placement?: TooltipPlacement;
  @Input('wTooltipOriginType') originType: 'pointer' | 'element' = 'element';
  // Defaults to `y: 4` for `element` origin
  @Input('wTooltipOffset') offset?: {x?: number; y?: number};
  @Input('wTooltipWidth') width?: number;
  @Input('wTooltip') content?: string | TemplateRef<void> | null;
  @Input('wTooltipDisabled') disabled = false;
  @Input('wTooltipDelay') delay = DELAY_DEFAULT;
  @Input('wTooltipClass') class?: string;
  // Defaults to `true` for `element` origin and `false` for `pointer` origin
  @Input('wTooltipArrow') arrow?: boolean;

  overlayInstance: OverlayInstance | null = null;
  delayTimer: number;

  private subs = subscriptions();

  // Store current pointer coords to use them as origin of the pointer-type tooltip
  private pointerCoords: Point;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    if (this.originType === 'pointer') {
      this.ngZone.runOutsideAngular(() => {
        this.elementRef.nativeElement.addEventListener('pointermove', this.handlePointerEvent);
      });
    }
  }

  ngOnChanges(changes: WSimpleChanges<TooltipDirective>) {
    if (!this.isShown) {
      return;
    }

    if (changes.disabled || changes.placement || changes.content || changes.class) {
      this.hide();
      // Immediate showing doesn't work because of some issues with change detection cycle - tooltip stays hidden.
      this.showWithDelay(0);
    }
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.removeEventListener('pointermove', this.handlePointerEvent);
    this.hide();
  }

  get isShown(): boolean {
    return Boolean(this.overlayInstance);
  }

  showWithDelay(delay = this.delay) {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(this.show, TEST ? DELAY_FOR_TEST : delay);
  }

  hide() {
    clearTimeout(this.delayTimer);
    this.overlayInstance?.hide();
  }

  // Used pointer events, because for disabled buttons mouse events not working
  @HostListener('pointerenter', ['$event']) onMouseEnter($event: PointerEvent) {
    this.handlePointerEvent($event);
    this.showWithDelay();
  }

  // Used pointer events, because for disabled buttons mouse events not working
  @HostListener('pointerleave') onMouseLeave() {
    this.hide();
  }

  show = () => {
    if (this.contentEmpty || this.disabled || this.isShown) {
      return;
    }

    const className = this.class ? `${this.class} overlay_no-pointer-events` : 'overlay_no-pointer-events';
    let origin: OverlayOrigin | HTMLElement;
    let {arrow, placement, offset} = this;

    if (this.originType === 'pointer') {
      arrow ??= false;
      placement ??= 'bottom left';
      offset ??= {x: 0, y: 4};
      origin = new PointerOverlayOrigin(this.elementRef.nativeElement, this.pointerCoords);
    } else {
      arrow ??= true;
      placement ??= 'top';
      origin = this.elementRef.nativeElement;
    }

    const convertedPlacement = convertPlacement(placement);

    if (!convertedPlacement) {
      return;
    }

    this.overlayInstance = this.overlay.show(TooltipComponent, {
      type: 'tooltip',
      props: {
        content: this.content!,
        placement,
        arrow,
        width: this.width,
      },
      placement: {
        ...convertedPlacement,
        offsetX: offset?.x,
        offsetY: offset?.y,
      },
      origin,
      class: className,
    });

    this.subs.add(
      this.overlayInstance.closed.subscribe(() => (this.overlayInstance = null)),
      this.overlayInstance.component.currentPlacement$
        .pipe(map(this.unconvertPlacement), filter(Boolean))
        .subscribe(newPlacement => this.overlayInstance?.updateContentProps({placement: newPlacement})),
    );
  };

  private get contentEmpty(): boolean {
    return toString(this.content).trim() === '';
  }

  private unconvertPlacement(placement: OverlayPlacement | null): TooltipPlacement | null {
    if (!placement) {
      return null;
    }

    const {originY, originX} = placement;

    return (`${originY} ${originX}`.replaceAll(/\s*center\s*/g, '') as TooltipPlacement) || null;
  }

  private handlePointerEvent = ({x, y}: PointerEvent) => {
    this.pointerCoords = {x, y};

    if (this.isShown && this.originType === 'pointer') {
      this.overlayInstance?.updatePointerOrigin(this.pointerCoords);
    }
  };
}
