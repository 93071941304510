import {InjectionToken} from '@angular/core';

import {Tag} from '../tags/tag-list/tag-list.component';
import {UserProfile} from '../../services/profile.types';

const FEDERATION_TAGS: Tag[] = [{color: 'green', label: 'AHQ main workspace'}];
const FEDERATION_CHILD_TAGS: Tag[] = [{color: 'green', label: 'AHQ managed workspace'}];

export function getProfileFederationTags(profile: UserProfile): Tag[] {
  if (profile.federation) {
    return FEDERATION_TAGS;
  }

  if (profile.federation_child) {
    return FEDERATION_CHILD_TAGS;
  }

  return [];
}

export const PROFILE_IS_BACK_OFFICE = new InjectionToken<boolean>('PROFILE_IS_BACK_OFFICE');
