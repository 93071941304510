<w-header theme="light">
  <div class="header-logo">
    <w-logo />
  </div>
  <div class="header-items">
    <ng-container *ngFor="let link of navigationLinks">
      <w-header-item *ngIf="link.href; else dropdownItemTmpl" [href]="link.href">{{ link.title }}</w-header-item>
      <ng-template #dropdownItemTmpl>
        <w-header-dropdown-trigger [dropdown]="dropdownTmpl">{{ link.title }}</w-header-dropdown-trigger>
        <ng-template #dropdownTmpl>
          <w-header-dropdown orientation="columns">
            <div *ngFor="let column of link.columns" class="header-dropdown__section">
              <div *ngFor="let section of column">
                <div *ngIf="section.title" class="header-dropdown__title">{{ section.title }}</div>
                <ul class="header-dropdown__items">
                  <ng-container *ngFor="let item of section.links">
                    <li *ngIf="item.href" class="header-dropdown__item">
                      <w-header-link [href]="item.href">{{ item.title }}</w-header-link>
                    </li>
                  </ng-container>
                </ul>
                <div *ngIf="section.view_all" class="header-dropdown__all">
                  <w-header-link type="all" [href]="section.view_all.href" [mixpanel]="section.view_all.title">{{
                    section.view_all.title
                  }}</w-header-link>
                </div>
              </div>
            </div>
          </w-header-dropdown>
        </ng-template>
      </ng-template>
    </ng-container>
    <hr class="header__separator" />
    <w-header-item *ngIf="isCompactView$ | async" class="header-item_login" href="/users/sign_in" mixpanel="Login"
      >Login</w-header-item
    >
    <w-header-item
      *ngIf="isCompactView$ | async"
      class="header-item_sign-up"
      [href]="'/request_demo' | websiteUrl"
      mixpanel="Request a demo"
      >Request a demo</w-header-item
    >
  </div>
  <div class="header-items header-items_right">
    <w-header-item class="header-item_login" href="/users/sign_in" mixpanel="Login">Login</w-header-item>
    <w-header-item
      class="header-item_sign-up"
      type="button"
      [href]="'/request_demo' | websiteUrl"
      mixpanel="Request a demo"
      >Request a demo</w-header-item
    >
  </div>
</w-header>
