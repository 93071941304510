import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';

import {AccountEnvironmentService, AccountEnvironmentTheme} from '../../services/account-environments.service';

@Component({
  selector: 'w-environment-type-bar',
  templateUrl: './environment-type-bar.component.html',
  styleUrls: ['./environment-type-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentTypeBarComponent {
  constructor(private accountEnvironments: AccountEnvironmentService) {}

  get theme(): AccountEnvironmentTheme | undefined {
    const theme = this.accountEnvironments.current?.theme;

    return theme === 'dev' ? undefined : theme;
  }
}
