import {NgClass} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import federationIcon from '@icons/sidebar/federation.svg';
import switchTeamIcon from '@icons/sidebar/switch-team.svg';

import {subscriptions} from '@shared/services/subscriptions';
import {HOTKEY_MAP} from '@shared/services/hotkeys.service';

import {AccountEnvironment} from '../../../services/account-environments.service';
import {AuthUser, AvailableTeam} from '../../../services/auth-user';
import {SidebarService} from '../sidebar.service';
import {GlobalHotkeys} from '../../../services/global-hotkeys.service';

@Component({
  selector: 'w-sidebar-user',
  templateUrl: './sidebar-user.component.html',
  styleUrls: ['./sidebar-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarUserComponent implements OnInit {
  @Input() env: AccountEnvironment | null = null;
  @Input() hasTeamSwitch = true;
  @Input() isSidebarOpened = false;
  @Input() isTeamSwitcherOpened = false;

  private subs = subscriptions();

  constructor(
    private authUser: AuthUser,
    private cd: ChangeDetectorRef,
    private sidebarService: SidebarService,
    private globalHotkeys: GlobalHotkeys,
  ) {}

  ngOnInit() {
    this.subs.add(this.authUser.currentTeamChanges$.subscribe(() => this.cd.markForCheck()));

    this.registerHotkey();
  }

  get workspaceAvatar(): string {
    return this.sessionWorkspace ? this.sessionWorkspace.avatar_url : this.authUser.workspace_image;
  }

  get workspaceName(): string {
    return this.sessionWorkspace ? this.sessionWorkspace.name : this.authUser.logged_workspace_name;
  }

  get workspaceNameTooltip(): string {
    return `${this.workspaceName} (${this.sessionWorkspace ? this.sessionWorkspace.group_name : 'my workspace'})`;
  }

  get fullLoggedUserName(): string {
    return `${this.authUser.logged_name} ${this.sessionWorkspace ? `(${this.sessionWorkspace.group_name})` : ''}`;
  }

  get sessionWorkspace(): AvailableTeam | null {
    return this.isTeamSession ? this.authUser.current_team : null;
  }

  get isTeamSession(): boolean {
    return this.authUser.team_session;
  }

  get isFederation(): boolean {
    return this.authUser.hasPrivilege('federation_management.read');
  }

  get iconId(): SvgIcon['id'] {
    return this.isFederation ? federationIcon.id : switchTeamIcon.id;
  }

  get teams(): AvailableTeam[] {
    return this.authUser.availableTeams;
  }

  get avatarClasses(): NgClass['ngClass'] {
    return {
      'sidebar-user__avatar': true,
      [`sidebar-user__avatar_env_${this.env?.theme}`]: Boolean(this.env),
    };
  }

  handleClick() {
    this.sidebarService.isTeamSwitcherOpened ? this.closeTeamSwitcher() : this.showTeamSwitcher();
  }

  hideSidebar() {
    this.sidebarService.hideSidebar();
  }

  closeTeamSwitcher() {
    this.sidebarService.closeTeamSwitcher();
  }

  private registerHotkey() {
    if (!this.hasTeamSwitch) {
      return;
    }

    this.globalHotkeys.add(
      HOTKEY_MAP.switch_team.hotkey,
      () => {
        if (this.sidebarService.isTeamSwitcherOpened) {
          this.hideSidebar();

          return;
        }

        if (this.sidebarService.isSubmenuOpened) {
          this.sidebarService.closeSubmenu();
        }

        this.sidebarService.openSidebar();

        this.showTeamSwitcher();
      },
      {
        description: HOTKEY_MAP.switch_team.description,
        priority: 10,
      },
    );
  }

  private showTeamSwitcher() {
    if (!this.hasTeamSwitch || this.sidebarService.isTeamSwitcherOpened) {
      return;
    }

    this.sidebarService.openTeamSwitcher();
  }
}
