import {ChangeDetectionStrategy, Component, Input, OnChanges, ViewEncapsulation} from '@angular/core';

import {WSimpleChanges} from '@shared/types/angular';

import {
  ACCOUNT_ENVIRONMENT_ICONS,
  AccountEnvironment,
  AccountEnvironmentService,
} from '../../../services/account-environments.service';

@Component({
  selector: 'w-env-switcher',
  templateUrl: './env-switcher.component.html',
  styleUrls: ['./env-switcher.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvSwitcherComponent implements OnChanges {
  @Input({required: true}) availableEnvironments: AccountEnvironment[];
  @Input() currentEnvironment: AccountEnvironment | null = null;

  environments: AccountEnvironment[] = [];

  icons = ACCOUNT_ENVIRONMENT_ICONS;

  constructor(private accountEnvironments: AccountEnvironmentService) {}

  ngOnChanges(changes: WSimpleChanges<EnvSwitcherComponent>) {
    if (changes.currentEnvironment || changes.availableEnvironments) {
      this.environments = this.currentEnvironment
        ? [this.currentEnvironment, ...this.availableEnvironments]
        : this.availableEnvironments;
    }
  }

  isCurrent(env: AccountEnvironment): boolean {
    return this.currentEnvironment === env;
  }

  handleClick(env: AccountEnvironment) {
    this.accountEnvironments.switchTo(env);
  }
}
