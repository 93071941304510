import _ from 'lodash';
import URL from 'url-parse';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import {RouterLinkActive} from '@angular/router';

import {subscriptions} from '@shared/services/subscriptions';
import {RouterUrl, WSimpleChanges} from '@shared/types/angular';
import {replaceString} from '@shared/utils/replace-string';

import {RouterHelpers} from '../../../services/router-helpers.service';

@Component({
  selector: 'w-header-link',
  templateUrl: './header-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLinkComponent implements OnInit, OnChanges {
  @Input() href?: string;
  @Input() routerUrl?: RouterUrl;
  @Input() type: 'default' | 'button' | 'icon' | 'all' = 'default';
  /**
   * Allows to specify extra url path patterns for which current link will be active.
   * Pattern matches the whole path e.g. `/` will match only `/` url.
   * Also wildcards are supported e.g. `/recipes/*` will match any path starting with `/recipes/`.
   */
  @Input() alsoActiveFor?: string | string[];
  @Input() mixpanel?: string;
  @Input() mixpanelOptions?: object;

  @ViewChild(RouterLinkActive) link: RouterLinkActive;

  alsoActiveForRegexps: RegExp[] | null = null;

  private subs = subscriptions();

  constructor(
    private cd: ChangeDetectorRef,
    private routeHelpers: RouterHelpers,
  ) {}

  ngOnInit() {
    this.subs.add(this.routeHelpers.routeChange$.subscribe(() => this.cd.markForCheck()));
  }

  ngOnChanges(changes: WSimpleChanges<HeaderLinkComponent>) {
    if (changes.alsoActiveFor) {
      this.alsoActiveForRegexps = this.alsoActiveFor ? this.convertPathPatterns(_.castArray(this.alsoActiveFor)) : null;
    }
  }

  get isActive(): boolean {
    if (this.isExternal) {
      return false;
    }

    const currentPathname = this.routeHelpers.currentPathname;
    let linkPathname = '';

    if (this.href) {
      linkPathname = new URL(this.href).pathname;
    } else if (this.routerUrl?.path) {
      linkPathname = typeof this.routerUrl.path === 'string' ? this.routerUrl.path : this.routerUrl.path.join('/');
    }

    let match: boolean = this.link ? this.link.isActive : currentPathname.startsWith(linkPathname);

    if (!match && this.alsoActiveForRegexps) {
      match = this.alsoActiveForRegexps.some(regexp => regexp.test(currentPathname));
    }

    return match;
  }

  get isExternal(): boolean {
    return Boolean(this.href && (this.href.startsWith('http') || this.href.startsWith('//')));
  }

  private convertPathPatterns(patterns: string[]): RegExp[] {
    return patterns.map(pattern => {
      const regexp = replaceString(pattern, '*', {
        replaceMatched: () => '.+?',
        replaceUnmatched: str => _.escapeRegExp(str),
      });

      return new RegExp(`^${regexp}$`, 'i');
    });
  }
}
