import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

interface LetContext<TContext> {
  $implicit: TContext;
  wLet: TContext;
}

@Directive({
  selector: '[wLet]',
})
export class LetDirective<TContext = unknown> {
  static ngTemplateContextGuard<T>(dir: LetDirective<T>, context: unknown): context is LetContext<T> {
    return true;
  }

  @Input({required: true}) set wLet(value: TContext) {
    if (this.context) {
      this.context.$implicit = this.context.wLet = value;
    } else {
      this.context = {$implicit: value, wLet: value};
      this.viewContainer.createEmbeddedView(this.templateRef, this.context);
    }
  }

  private context?: LetContext<TContext>;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<LetContext<TContext>>,
  ) {}
}
