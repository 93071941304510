import {Injectable} from '@angular/core';

import {PagedParams, PagedResponse} from '../../types';
import {Request} from '../../services/http-resource';

import {
  DataTable,
  DataTableColumn,
  DataTableMeta,
  DataTableQueryFilter,
  DataTableRowsResult,
  DataTableSorting,
} from './data-table.types';

export type DataTableUnifiedId = DataTable['id'] | DataTable['table_id'];

export type DataTableMetaListRequestParams = PagedParams<{
  sort_by: 'name' | 'folder_id';
  folder_id: number | null;
}>;

export type DataTableListRequestParams = DataTableMetaListRequestParams & {include_schema: true};

export interface DataTableRowsRequestParams {
  continuationToken: string | null;
  sorting: DataTableSorting | null;
  filter: DataTableQueryFilter | null;
  limit: number;
}

@Injectable()
export abstract class AbstractDataTableService<TContext extends object = object> {
  abstract getAll(params?: DataTableListRequestParams, context?: TContext): Request<PagedResponse<DataTable>>;

  abstract getAllMeta(
    params?: DataTableMetaListRequestParams,
    context?: TContext,
  ): Request<PagedResponse<DataTableMeta>>;

  abstract get(id: DataTableUnifiedId, context?: TContext): Request<DataTable>;

  abstract getRows(
    id: DataTableUnifiedId,
    params?: Partial<DataTableRowsRequestParams>,
    context?: TContext,
  ): Request<DataTableRowsResult>;

  abstract getRelatedRows(
    id: DataTableUnifiedId,
    params?: Partial<DataTableRowsRequestParams>,
    context?: TContext & {initiatorColumnId?: DataTableColumn['id']},
  ): Request<DataTableRowsResult>;
}
