import _ from 'lodash';
import {Injectable, NgZone} from '@angular/core';

import {MixpanelEvent, MixpanelEventParams} from '../types';

@Injectable({
  providedIn: 'root',
})
export class Mixpanel {
  constructor(private ngZone: NgZone) {}

  peopleSet(properties: object) {
    mixpanel.people.set(properties);
  }

  track(event: MixpanelEvent, params?: MixpanelEventParams, callback?: () => void) {
    let timeoutId: number;
    let called = false;

    this.ngZone.runOutsideAngular(() => {
      if (callback) {
        timeoutId = setTimeout(onTrack, 500);
        this.trackEvent(event, params, onTrack);
      } else {
        this.trackEvent(event, params);
      }
    });

    function onTrack() {
      if (!called) {
        called = true;
        clearTimeout(timeoutId);
        callback!();
      }
    }
  }

  trackDomEvent(event: MouseEvent, mixpanelEvent: MixpanelEvent, mixpanelParams?: MixpanelEventParams) {
    if (typeof mixpanel === 'undefined') {
      return;
    }

    const elem = event.currentTarget as Element;

    /*
     * We should handle case when user clicks a link and it will be opened in the current tab/window.
     * In this case browser may cancel mixpanel request when going to another page,
     * so we should give it a time to finish and navigate manually.
     */
    const shouldWaitForMixpanel =
      // User clicks using first mouse button without modifiers
      event.type === 'click' &&
      event.which === 1 &&
      !(event.metaKey || event.ctrlKey) &&
      // Is link to this window / tab
      this.isLinkToSameWindow(elem) &&
      // Default browser navigation is not prevented
      !event.defaultPrevented;

    if (shouldWaitForMixpanel) {
      event.preventDefault();

      const href = elem.getAttribute('href') || '';

      this.track(mixpanelEvent, mixpanelParams, () => {
        location.href = href;
      });
    } else {
      this.track(mixpanelEvent, mixpanelParams);
    }
  }

  private isLinkToSameWindow(elem: Element): boolean {
    if (elem.tagName === 'A') {
      const hrefAttr = elem.getAttribute('href') || '';
      const targetAttr = elem.getAttribute('target');

      return Boolean(hrefAttr) && hrefAttr.charAt(0) !== '#' && (!targetAttr || targetAttr === '_self');
    } else {
      return false;
    }
  }

  private trackEvent(event: MixpanelEvent, params?: MixpanelEventParams, callback?: () => void) {
    if (typeof mixpanel === 'undefined' || !mixpanel) {
      return;
    }

    if (_.isArray(mixpanel)) {
      mixpanel.push(['track', event, params, callback]);
    } else if (_.isFunction(mixpanel.track)) {
      mixpanel.track(event, params, callback);
    }
  }
}
