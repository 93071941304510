import {Directive, ElementRef, HostBinding, Input, NgZone, OnDestroy, OnInit} from '@angular/core';

/*
 * 'when-scrollable': trap scroll only if element is currently scrollable (default)
 * 'always': always trap scroll (even if element currently doesn't have scroll)
 */
type TrapMode = 'when-scrollable' | 'always';

@Directive({
  selector: '[wTrapScroll]',
  standalone: true,
})
export class TrapScrollDirective implements OnInit, OnDestroy {
  @Input({alias: 'wTrapScroll', required: true}) mode: TrapMode | '';

  @HostBinding('class.trap-scroll') cssClass = true;

  private elem: HTMLElement;

  constructor(
    private elemRef: ElementRef,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    this.elem = this.elemRef.nativeElement;
    this.ngZone.runOutsideAngular(() => {
      this.elem.addEventListener('wheel', this.handleMouseScroll);
    });
  }

  ngOnDestroy() {
    this.elem.removeEventListener('wheel', this.handleMouseScroll);
  }

  private getClosestScrollingElem(target: Element, isScrollingUp: boolean): Element | null {
    const hostElem = this.elem;

    let elem: Element | null = target;

    while (elem && elem !== hostElem) {
      if ((isScrollingUp && elem.scrollTop > 0) || (!isScrollingUp && this.elemCanBeScrolledDown(elem))) {
        break;
      }

      elem = elem.parentElement;
    }

    return elem;
  }

  private elemCanBeScrolledDown(elem: Element): boolean {
    if (elem.scrollTop + elem.clientHeight < elem.scrollHeight) {
      const {overflowY} = window.getComputedStyle(elem);

      return overflowY !== 'visible' && overflowY !== 'hidden';
    } else {
      return false;
    }
  }

  private cancelEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  private handleMouseScroll = (event: WheelEvent) => {
    let scrollDelta = event.deltaY;

    if (!scrollDelta) {
      return;
    }

    const isScrollingUp = scrollDelta < 0;
    const scrollingElem = this.getClosestScrollingElem(event.target as Element, isScrollingUp);

    if (scrollingElem !== this.elem) {
      return;
    }

    const elemScrollDistance = scrollingElem.scrollHeight - scrollingElem.clientHeight;

    scrollDelta = Math.abs(scrollDelta);

    if (elemScrollDistance <= 0) {
      if (this.mode === 'always') {
        this.cancelEvent(event);
      }
    } else if (isScrollingUp && scrollingElem.scrollTop <= scrollDelta) {
      scrollingElem.scrollTop = 0;
      this.cancelEvent(event);
    } else if (!isScrollingUp && elemScrollDistance - scrollingElem.scrollTop <= scrollDelta) {
      scrollingElem.scrollTop = scrollingElem.scrollHeight;
      this.cancelEvent(event);
    }
  };
}
