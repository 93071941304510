<a
  class="team-item"
  href="/users/switch_team?team_id={{ team.id | wUrlEncode }}&team_name={{ team.name | wUrlEncode }}"
  (click)="handleClick($event)"
  wKeyboardNavigationItem
>
  <w-user-avatar class="team-item__img" [imgUrl]="team.avatar_url" [userName]="team.name" [size]="28" />
  <div class="team-item__text-wrapper">
    <w-trimmed-text
      class="team-item__name"
      highlightClass="team-item__highlight"
      [text]="team.name"
      [highlightTerm]="searchQuery"
    />
    <div class="team-item__group">{{ groupName }}</div>
  </div>
</a>
