import _ from 'lodash';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {PAGE_ERROR_DATA} from '@shared/services/service-pages.service';
import {ErrorData, ErrorPageParams, ErrorPageProps} from '@shared/services/service-pages.types';

import {Mixpanel} from '../../../services/mixpanel';
import {PageService} from '../../../services/page.service';
import {RouterHelpers} from '../../../services/router-helpers.service';

@Component({
  selector: 'w-page-error',
  templateUrl: './page-error.component.html',
})
export class PageErrorComponent implements OnInit {
  data: ErrorData;
  canGoBack = false;

  constructor(
    private mixpanel: Mixpanel,
    private route: ActivatedRoute,
    private pageService: PageService,
    private routerHelpers: RouterHelpers<{}, ErrorPageProps>,
  ) {}

  ngOnInit() {
    this.data = _.defaultsDeep(
      {},
      this.routerHelpers.navigationState,
      PAGE_ERROR_DATA[(this.route.snapshot.params as ErrorPageParams).errorCode || '404'],
    );
    this.canGoBack = Boolean(this.data.buttons.back) && window.history.length > 1;
    this.pageService.title = this.data.title;
    this.pageService.addClass('error-page');
  }

  goBack() {
    this.mixpanel.track(`${this.data.type} page: Go back`, {}, () => {
      window.history.back();
    });
  }

  refresh() {
    this.mixpanel.track(`${this.data.type} page: Refresh`, {}, () => {
      window.location.reload();
    });
  }
}
