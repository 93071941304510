import {Injectable} from '@angular/core';

import {Adapter} from '../types';

import {AuthUser} from './auth-user';
import {Mixpanel} from './mixpanel';
import {AuthUserAdHocFeatures} from './auth-user.types';

export const AD_HOC_AI_FEATURES: Array<keyof AuthUserAdHocFeatures> = [
  'ai_helper_sdk',
  'ai_helper_recipe',
  'ai_helper_connector',
];
export const AD_HOC_AI_ADAPTER: Adapter['name'] = 'open_ai_utility';
export const AD_HOC_IDP_ADAPTER: Adapter['name'] = 'workato_idp';
export const AD_HOC_RAG_ADAPTER: Adapter['name'] = 'workato_rag';

@Injectable({
  providedIn: 'root',
})
export class CopilotHelper {
  constructor(
    private authUser: AuthUser,
    private mixpanel: Mixpanel,
  ) {}

  get isRecipeCopilotEnabled(): boolean {
    return this.authUser.hasAdHocFeatures('ai_helper_recipe') && this.hasUserConsent;
  }

  get isSDKCopilotEnabled(): boolean {
    return this.authUser.hasAdHocFeatures('ai_helper_sdk') && this.hasUserConsent;
  }

  get hasUserConsent(): boolean {
    return this.authUser.coworker_enabled;
  }

  get hasSomeAiFeaturesEnabled(): boolean {
    return (
      AD_HOC_AI_FEATURES.some(feature => this.authUser.hasAdHocFeatures(feature)) ||
      this.authUser.coworker_enabled ||
      this.authUser.hasAdHocAdapter(AD_HOC_AI_ADAPTER) ||
      this.authUser.hasAdHocAdapter(AD_HOC_IDP_ADAPTER) ||
      this.authUser.hasAdHocAdapter(AD_HOC_RAG_ADAPTER)
    );
  }

  setAiMixpanelProperties() {
    this.mixpanel.peopleSet({
      recipe_copilot_enabled: this.authUser.hasAdHocFeatures('ai_helper_recipe'),
      sdk_copilot_enabled: this.authUser.hasAdHocFeatures('ai_helper_sdk'),
      copilot_consent: this.authUser.coworker_enabled,
      ai_connector_enabled: this.authUser.hasAdHocFeatures('ai_helper_connector'),
      ai_connector_consent: this.authUser.hasAdHocAdapter(AD_HOC_AI_ADAPTER),
      idp_connector_enabled: this.authUser.hasPrivilege('ai_helper_idp.all'),
      idp_connector_consent: this.authUser.hasAdHocAdapter(AD_HOC_IDP_ADAPTER),
      rag_connector_enabled: this.authUser.hasPrivilege('ai_helper_rag.all'),
      rag_connector_consent: this.authUser.hasAdHocAdapter(AD_HOC_RAG_ADAPTER),
    });
  }
}
