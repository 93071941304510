import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs';

import {LayoutService} from '@shared/services/layout.service';

import {FixedHeaderService} from '../../../services/fixed-header.service';
import {GuestHeaderLink} from '../header.types';

import {headerLinks} from './header-links';

@Component({
  selector: 'w-guest-header',
  templateUrl: './guest-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestHeaderComponent implements AfterViewInit, OnDestroy {
  navigationLinks: GuestHeaderLink[];
  isCompactView$: Observable<boolean>;

  constructor(
    private layoutService: LayoutService,
    private fixedHeader: FixedHeaderService,
    private elementRef: ElementRef,
  ) {
    this.navigationLinks = headerLinks;
    this.isCompactView$ = this.layoutService.isCompactView$;
  }

  ngAfterViewInit() {
    this.fixedHeader.add(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.fixedHeader.remove(this.elementRef.nativeElement);
  }
}
