import {Injectable} from '@angular/core';
import prodIcon from '@icons/environments/prod.svg';
import devIcon from '@icons/environments/dev.svg';
import sandIcon from '@icons/environments/sand.svg';
import test1Icon from '@icons/environments/test1.svg';
import test2Icon from '@icons/environments/test2.svg';
import test3Icon from '@icons/environments/test3.svg';

import {CssColorVariableName} from '../theming/theme-types';

import {AuthUser} from './auth-user';

type AccountEnvironmentTypeWithFixedTheme = 'dev' | 'prod' | 'sandbox';
type AccountEnvironmentTypeWithFluidTheme = 'test' | 'stage' | 'preprod' | 'uat' | 'sandbox';

export type AccountEnvironmentType = AccountEnvironmentTypeWithFixedTheme | AccountEnvironmentTypeWithFluidTheme;

type AccountEnvironmentFluidTheme = 'test1' | 'test2' | 'test3';

export type AccountEnvironmentTheme = 'dev' | 'prod' | 'sandbox' | AccountEnvironmentFluidTheme;

export interface AccountEnvironment {
  name: string;
  id: number;
  type: AccountEnvironmentType;
  theme: AccountEnvironmentTheme;
  shortTypeName: string;
}

export const ACCOUNT_ENVIRONMENT_ICONS: Record<AccountEnvironmentTheme, SvgIcon['id']> = {
  prod: prodIcon.id,
  dev: devIcon.id,
  sandbox: sandIcon.id,
  test1: test1Icon.id,
  test2: test2Icon.id,
  test3: test3Icon.id,
};

export type EnvironmentColors = [
  primary: CssColorVariableName,
  secondary: CssColorVariableName,
  border: CssColorVariableName,
  text: CssColorVariableName,
  muted: CssColorVariableName,
];

export const ACCOUNT_ENVIRONMENT_COLORS: Record<AccountEnvironmentTheme, EnvironmentColors> = {
  dev: ['env-dev-default', 'env-dev-subtle', 'env-dev-border', 'fg-onEnv-dev', 'env-dev-muted'],
  prod: ['env-prod-default', 'env-prod-subtle', 'env-prod-border', 'fg-onEnv-prod', 'env-prod-muted'],
  sandbox: ['indigo-default', 'indigo-subtle', 'indigo-border', 'fg-onIndigo', 'indigo-muted'],
  test1: ['env-test-default', 'env-test-subtle', 'env-test-border', 'fg-onEnv-test', 'env-test-muted'],
  test2: ['olive-default', 'olive-subtle', 'olive-border', 'fg-onOlive', 'olive-muted'],
  test3: ['rust-default', 'rust-subtle', 'rust-border', 'fg-onRust', 'rust-muted'],
};

export const ACCOUNT_ENVIRONMENT_SHORT_NAMES: Record<AccountEnvironmentType, string> = {
  dev: 'dev',
  prod: 'prod',
  sandbox: 'sand',
  test: 'test',
  preprod: 'pre',
  uat: 'uat',
  stage: 'stage',
} as const;

export const ACCOUNT_ENVIRONMENT_LABELS: Record<AccountEnvironmentType, string> = {
  dev: 'Development',
  prod: 'Production',
  sandbox: 'Sandbox',
  test: 'Test',
  preprod: 'Pre-production',
  uat: 'UAT',
  stage: 'Stage',
} as const;

const ACCOUNT_ENVIRONMENT_WITH_FIXED_THEME: AccountEnvironmentTypeWithFixedTheme[] = ['dev', 'prod', 'sandbox'];

export const ACCOUNT_ENVIRONMENT_FLUID_THEMES: AccountEnvironmentFluidTheme[] = ['test1', 'test2', 'test3'];

export interface CreateThemeForTypeResult {
  theme: AccountEnvironmentTheme;
  shouldIncrementFluidIndex: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AccountEnvironmentService {
  static createThemeForType(type: AccountEnvironment['type'], fluidThemeIndex = 0): CreateThemeForTypeResult {
    if (ACCOUNT_ENVIRONMENT_WITH_FIXED_THEME.includes(type as AccountEnvironmentTypeWithFixedTheme)) {
      return {
        theme: type as AccountEnvironmentTheme,
        shouldIncrementFluidIndex: false,
      };
    }

    return {theme: ACCOUNT_ENVIRONMENT_FLUID_THEMES[fluidThemeIndex], shouldIncrementFluidIndex: true};
  }

  all: AccountEnvironment[] = [];
  available: AccountEnvironment[] = [];
  current: AccountEnvironment | null = null;

  private fluidThemeIndex = 0;

  constructor(authUser: AuthUser) {
    const {current_environment: current, environments} = authUser;

    if (current) {
      this.all = environments.map(env => ({
        ...env,
        theme: this.createThemeForType(env.type),
        shortTypeName: ACCOUNT_ENVIRONMENT_SHORT_NAMES[env.type],
      }));

      this.current = this.all.find(env => env.id === current.id)!;
      this.available = this.all.filter(env => env.id !== current.id);
    }
  }

  get isEnabled(): boolean {
    return this.current !== null;
  }

  get isDev(): boolean {
    return this.current?.type === 'dev';
  }

  get isSecondary(): boolean {
    return this.isEnabled && !this.isDev;
  }

  findEnvironment(id: AccountEnvironment['id']): AccountEnvironment | undefined {
    return this.all.find(env => env.id === id)!;
  }

  findEnvironmentByType(type: AccountEnvironment['type']): AccountEnvironment | undefined {
    return this.all.find(env => env.type === type)!;
  }

  switchTo(env: AccountEnvironment, redirectTo: string | null = null, blank = false) {
    const switchEnvironmentLink = this.getEnvironmentSwitchUrl(env.id, redirectTo);

    window.open(switchEnvironmentLink, blank ? '_blank' : '_self');
  }

  private getEnvironmentSwitchUrl(envId: AccountEnvironment['id'], redirectTo: string | null = null): string {
    const redirect = redirectTo ? `&return_to=${redirectTo}` : '';

    return `/users/switch_environment?environment_id=${envId}${redirect}`;
  }

  private createThemeForType(type: AccountEnvironment['type']): AccountEnvironmentTheme {
    const {theme, shouldIncrementFluidIndex} = AccountEnvironmentService.createThemeForType(type, this.fluidThemeIndex);

    if (shouldIncrementFluidIndex) {
      this.fluidThemeIndex = ++this.fluidThemeIndex % ACCOUNT_ENVIRONMENT_FLUID_THEMES.length;
    }

    return theme;
  }
}
