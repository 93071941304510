import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {RouterLinkActive} from '@angular/router';
import URL from 'url-parse';
import _ from 'lodash';
import {v4 as uuid} from 'uuid';

import {subscriptions} from '@shared/services/subscriptions';
import {RouterUrl, WSimpleChanges} from '@shared/types/angular';
import {replaceString} from '@shared/utils/replace-string';
import {OverlayContentDirective} from '@shared/modules/overlay/overlay-content.directive';
import {Overlay} from '@shared/modules/overlay/overlay.service';
import {OverlayInstance} from '@shared/modules/overlay/overlay.types';
import {LayoutService} from '@shared/services/layout.service';

import {RouterHelpers} from '../../../services/router-helpers.service';
import {SIDEBAR_TOGGLE_DELAY, SUBMENU_ANIMATION_TIME, SUBMENU_TOGGLE_DELAY, SidebarService} from '../sidebar.service';
import {SidebarIconType, SidebarSubMenuItem, SidebarSubMenuLink} from '../sidebar.types';
import {SidebarComponent} from '../sidebar/sidebar.component';

import {SidebarItemIconDirective} from './sidebar-item-icon.directive';

interface SidebarLink {
  href?: string;
  routerUrl?: RouterUrl;
  alsoActiveForRegexps: RegExp[] | null;
}

@Component({
  selector: 'w-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['../styles/sidebar-item.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarItemComponent implements SidebarLink, OnInit, OnChanges {
  @HostBinding('attr.aria-label') @Input({required: true}) text: string;
  @Input() href?: string;
  @Input() routerUrl?: RouterUrl;
  @Input() subMenu?: SidebarSubMenuItem[];
  @Input() iconId?: SidebarIconType;
  /**
   * Allows to specify extra url path patterns for which current link will be active.
   * Pattern matches the whole path e.g. `/` will match only `/` url.
   * Also wildcards are supported e.g. `/recipes/*` will match any path starting with `/recipes/`.
   */
  @Input() alsoActiveFor?: string | string[];
  @Input('mixpanel') mixpanelInput?: string;
  @Input() mixpanelOptions?: object;

  @ViewChild(OverlayContentDirective, {static: true}) subMenuContent: OverlayContentDirective;
  @ViewChild(RouterLinkActive) routerLinkActive?: RouterLinkActive;

  @ContentChild(SidebarItemIconDirective, {read: TemplateRef}) iconTemplate?: TemplateRef<any>;
  @HostBinding('attr.role') role = 'menuitem';

  isSidebarOpened = false;

  alsoActiveForRegexps: RegExp[] | null = null;

  submenuAriaLabelId = `navigation-submenu-${uuid()}`;
  private subMenuLinks?: SidebarLink[];

  private _isSubmenuOpenedForTablet = false;
  private subMenuInstance?: OverlayInstance;
  private timer: number | null = null;
  private subs = subscriptions();

  constructor(
    private routeHelpers: RouterHelpers,
    private sidebarService: SidebarService,
    private overlay: Overlay,
    private layoutService: LayoutService,
    private elementRef: ElementRef,
    private cd: ChangeDetectorRef,
    private parent: SidebarComponent,
  ) {}

  ngOnInit() {
    this.subs.add(
      this.sidebarService.isSidebarOpened$.subscribe(isOpened => {
        this.isSidebarOpened = isOpened;

        this.cd.markForCheck();
      }),

      this.routeHelpers.routeChange$.subscribe(() => {
        this.cd.markForCheck();
      }),
    );

    if (this.hasMultipleSubmenuItems) {
      this.subs.add(
        this.sidebarService.isSubmenuOpened$.subscribe(isOpened => {
          if (!isOpened) {
            this.hideSubmenu();
          }
        }),
      );
    }
  }

  ngOnChanges(changes: WSimpleChanges<SidebarItemComponent>) {
    if (changes.alsoActiveFor) {
      this.alsoActiveForRegexps = this.alsoActiveFor ? this.convertPathPatterns(this.alsoActiveFor) : null;
    }

    if (changes.subMenu) {
      this.subMenuLinks = this.subMenu
        ?.filter(item => item.type === 'link')
        .map((item: SidebarSubMenuLink) => ({
          href: item.href,
          routerUrl: item.routerUrl,
          alsoActiveForRegexps: item.alsoActiveFor ? this.convertPathPatterns(item.alsoActiveFor) : null,
        }));
    }
  }

  get isActive(): boolean {
    return this.isActiveLink(this);
  }

  get subMenuHasActiveItem(): boolean {
    return Boolean(this.subMenuLinks?.some(link => this.isActiveLink(link)));
  }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  get isSubmenuOpened(): boolean {
    return Boolean(this.subMenuInstance);
  }

  get isSubmenuOpenedForTablet(): boolean {
    return this.isCompact && this._isSubmenuOpenedForTablet;
  }

  get hasMultipleSubmenuItems(): boolean {
    return Boolean(this.subMenu && this.subMenu.length > 1);
  }

  get isCompact(): boolean {
    return this.layoutService.isCompactLayout;
  }

  get firstSubMenuLink(): SidebarSubMenuLink | null {
    return this.subMenu?.[0].type === 'link' ? this.subMenu[0] : null;
  }

  get mixpanel(): string | undefined {
    return this.mixpanelInput ? `Header navigation: ${this.mixpanelInput}` : undefined;
  }

  @HostListener('mouseenter')
  handleHostMouseenter() {
    if (this.parent.isSidebarOpened || this.isCompact) {
      return;
    }

    this.parent.openSidebar();
  }

  handleSubmenuTriggerMouseenter() {
    if (this.isCompact) {
      return;
    }

    this.stopTimer();

    let delay = SUBMENU_TOGGLE_DELAY;

    if (!this.sidebarService.isSidebarOpened) {
      delay += SIDEBAR_TOGGLE_DELAY;
    }

    this.timer = setTimeout(() => {
      this.showSubmenu();
    }, delay);
  }

  handleSubmenuTriggerMouseleave(event: MouseEvent) {
    if (this.isCompact || this.subMenuInstance?.element.contains(event.relatedTarget as Element)) {
      return;
    }

    this.stopTimer();

    this.timer = setTimeout(() => {
      this.sidebarService.closeSubmenu();
    }, SUBMENU_TOGGLE_DELAY);
  }

  handleSubmenuTriggerClick() {
    if (!this.isCompact) {
      return;
    }

    this._isSubmenuOpenedForTablet = !this._isSubmenuOpenedForTablet;
  }

  showSubmenu() {
    if (this.isSubmenuOpened || !this.sidebarService.isSidebarOpened) {
      return;
    }

    this.subMenuInstance = this.overlay.show(this.subMenuContent, {
      origin: this.element,
      placement: {
        originX: 'left',
        originY: 'top',
        overlayX: 'right',
        overlayY: 'top',
        offsetY: -24,
      },
    });

    this.subMenuInstance.element.addEventListener('mouseleave', this.submenuMouseleave);
    this.subMenuInstance.element.addEventListener('mouseenter', this.submenuMouseenter);

    this.sidebarService.openSubmenu();

    this.cd.markForCheck();
  }

  hideSubmenu() {
    if (!this.subMenuInstance) {
      return;
    }

    this.subMenuInstance.element.removeEventListener('mouseleave', this.submenuMouseleave);
    this.subMenuInstance.element.removeEventListener('mouseenter', this.submenuMouseenter);

    this.subMenuInstance.hide();
    this.subMenuInstance = undefined;
    this.cd.markForCheck();
  }

  hideSidebar() {
    if (this.isCompact) {
      this.parent.hideSidebar();

      return;
    }

    this.hideSubmenu();

    setTimeout(() => {
      this.parent.hideSidebar();
    }, SUBMENU_ANIMATION_TIME / 2);
  }

  private isActiveLink(link: SidebarLink): boolean {
    const {currentPathname} = this.routeHelpers;
    let linkPathname = '';

    if (link.href) {
      linkPathname = new URL(link.href).pathname;
    } else if (link.routerUrl?.path) {
      linkPathname = typeof link.routerUrl.path === 'string' ? link.routerUrl.path : link.routerUrl.path.join('/');
    }

    let match = this.routerLinkActive ? this.routerLinkActive.isActive : currentPathname.startsWith(linkPathname);

    if (!match && link.alsoActiveForRegexps) {
      match = link.alsoActiveForRegexps.some(regexp => regexp.test(currentPathname));
    }

    return match;
  }

  private stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private convertPathPatterns(patterns: string | string[]): RegExp[] {
    return _.castArray(patterns).map(pattern => {
      const regexp = replaceString(pattern, '*', {
        replaceMatched: () => '.+?',
        replaceUnmatched: str => _.escapeRegExp(str),
      });

      return new RegExp(`^${regexp}$`, 'i');
    });
  }

  private submenuMouseleave = (event: MouseEvent) => {
    if (event.relatedTarget instanceof Element) {
      if (!this.parent.element.contains(event.relatedTarget)) {
        this.hideSidebar();

        return;
      }

      if (!this.element.contains(event.relatedTarget)) {
        this.sidebarService.closeSubmenu();
      }
    } else {
      this.hideSidebar();
    }
  };

  private submenuMouseenter = () => {
    this.stopTimer();
  };
}
