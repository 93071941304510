import {Injectable, inject} from '@angular/core';
import {DateTime} from 'luxon';
import {ExtendedScrollToOptions} from '@angular/cdk/scrolling';
import {isEmpty, isNil, isPlainObject, last} from 'lodash';
import {v4 as uuid} from 'uuid';

import {nonNullable} from '../../utils/non-nullable';
import {AbstractDateFormatService} from '../../services/abstract-date-format.service';

import {
  CELL_BORDER_WIDTH,
  FIRST_COL_WIDTH,
  HEADER_HEIGHT,
  INVALID_RELATION_TEXT,
  MAX_DEFAULT_LENGTH,
  MAX_TEXT_LENGTH,
  MIN_FOOTER_HEIGHT,
  ROW_HEIGHT,
  ROW_ID_KEY,
  SCROLL_BEHAVIOUR,
  TABLE_BORDER_WIDTH,
} from './data-table.constants';
import {
  DataTable,
  DataTableCellValue,
  DataTableColumn,
  DataTableColumnRelationRef,
  DataTableColumnType,
  DataTableConfiguration,
  DataTableFile,
  DataTableInvalidRelation,
  DataTableRow,
  DataTableRowErrors,
  DataTableRowId,
  DataTableValidRelation,
} from './data-table.types';

const DEFAULT_COLUMN_NAME_REGEX = /^Column ([0-9]+)$/;
const PENDING_CREATION_COL_ID = '$$col_pending_creation';
const PENDING_CREATION_ROW_ID_PREFIX = '$$row_pending_creation';

const TEXT_LIKE_TYPES = ['short-text', 'long-text'] satisfies DataTableColumnType[];
const NUMBER_LIKE_TYPES = ['number', 'integer'] satisfies DataTableColumnType[];
const DATE_LIKE_TYPES = ['date', 'date-time'] satisfies DataTableColumnType[];

export type DataTableTextLikeColumnType = Extract<DataTableColumnType, (typeof TEXT_LIKE_TYPES)[number]>;
export type DataTableNumberLikeColumnType = Extract<DataTableColumnType, (typeof NUMBER_LIKE_TYPES)[number]>;
export type DataTableDateLikeColumnType = Extract<DataTableColumnType, (typeof DATE_LIKE_TYPES)[number]>;

export type DataTableViewportMargin = [top: number, right: number, bottom: number, left: number];

@Injectable({
  providedIn: 'root',
})
export class DataTableHelper {
  private dateFormat = inject(AbstractDateFormatService);

  getInitialColumnId(visibleColumns: DataTableColumn[]): DataTableColumn['id'] | null {
    return (visibleColumns.find(column => !column.read_only) || visibleColumns[0])?.id || null;
  }

  getTableViewportMargin(viewport: HTMLElement, includeBordersAndScrollbars = true): DataTableViewportMargin {
    let top = viewport.querySelector<HTMLElement>('[data-table-header]')!.offsetHeight;
    let left = viewport.querySelector<HTMLElement>('[data-table-header-index-cell]')?.offsetWidth ?? 0;
    let right = viewport.querySelector<HTMLElement>('[data-table-header-controls-cell]')?.offsetWidth ?? 0;
    let bottom = viewport.querySelector<HTMLElement>('[data-table-footer]')?.offsetHeight ?? 0;

    bottom = Math.min(bottom, MIN_FOOTER_HEIGHT);

    if (includeBordersAndScrollbars) {
      const vScrollbarSize = viewport.offsetWidth - viewport.clientWidth - TABLE_BORDER_WIDTH * 2;
      const hScrollbarSize = viewport.offsetHeight - viewport.clientHeight - TABLE_BORDER_WIDTH * 2;

      top += TABLE_BORDER_WIDTH;
      right += TABLE_BORDER_WIDTH + vScrollbarSize;
      bottom += TABLE_BORDER_WIDTH + hScrollbarSize;
      left += TABLE_BORDER_WIDTH;
    }

    return [top, right, bottom, left];
  }

  getMinTableInnerHeight(numberOfRows: number, isFooterEmpty: boolean): number {
    return (
      HEADER_HEIGHT +
      numberOfRows * (ROW_HEIGHT + CELL_BORDER_WIDTH) +
      (isFooterEmpty ? 0 : MIN_FOOTER_HEIGHT + CELL_BORDER_WIDTH)
    );
  }

  getScrollToColumnOptions(headerCellElement: HTMLElement, scrollToTop = false): ExtendedScrollToOptions {
    return {
      left: headerCellElement.offsetLeft - FIRST_COL_WIDTH,
      top: scrollToTop ? 0 : undefined,
      behavior: SCROLL_BEHAVIOUR,
    };
  }

  getScrollToCellOptions(cellElement: HTMLElement, viewport?: HTMLElement): ExtendedScrollToOptions | null {
    if (!viewport) {
      return null;
    }

    const {left: vpLeft, right: vpRight, top: vpTop, bottom: vpBottom} = viewport.getBoundingClientRect();
    const {left: cellLeft, right: cellRight, top: cellTop, bottom: cellBottom} = cellElement.getBoundingClientRect();
    // Note that offsetLeft includes first column width, while offsetTop does not include header height.
    const {offsetLeft, offsetTop, offsetWidth, offsetHeight} = cellElement;
    const [topMargin, rightMargin, bottomMargin, leftMargin] = this.getTableViewportMargin(viewport);
    const leftAlignedPosition = offsetLeft - (leftMargin - TABLE_BORDER_WIDTH);
    const topAlignedPosition = offsetTop;
    let left: number | undefined;
    let top: number | undefined;

    if (cellLeft < vpLeft + leftMargin) {
      left = leftAlignedPosition;
    } else if (cellRight > vpRight - rightMargin) {
      left = leftAlignedPosition - (viewport.offsetWidth - offsetWidth - leftMargin - rightMargin);
    }

    if (cellTop < vpTop + topMargin) {
      top = topAlignedPosition;
    } else if (cellBottom > vpBottom - bottomMargin) {
      top = topAlignedPosition - (viewport.offsetHeight - offsetHeight - topMargin - bottomMargin);
    }

    return typeof left === 'number' || typeof top === 'number' ? {left, top, behavior: SCROLL_BEHAVIOUR} : null;
  }

  getStoragePrefix(id: DataTable['id'] | DataTable['table_id']): string {
    return `DataTable.${id}`;
  }

  isPendingCreationColumn(columnOrId: DataTableColumn | DataTableColumn['id']): boolean {
    const columnId = typeof columnOrId === 'string' ? columnOrId : columnOrId.id;

    return columnId === PENDING_CREATION_COL_ID;
  }

  isPendingCreationRow(rowOrId: DataTableRow | DataTableRowId): boolean {
    const rowId = typeof rowOrId === 'string' ? rowOrId : rowOrId[ROW_ID_KEY];

    return rowId.startsWith(PENDING_CREATION_ROW_ID_PREFIX);
  }

  isPendingCreationCell(row: DataTableRow, columnId: DataTableColumn['id']): boolean {
    return this.isPendingCreationRow(row) && columnId === ROW_ID_KEY;
  }

  isEmptyValue(value: DataTableCellValue): boolean {
    return isNil(value) || value === '';
  }

  isNumberLikeType(type: DataTableColumnType): type is DataTableNumberLikeColumnType {
    return (NUMBER_LIKE_TYPES as DataTableColumnType[]).includes(type);
  }

  isDateLikeType(type: DataTableColumnType): type is DataTableDateLikeColumnType {
    return (DATE_LIKE_TYPES as DataTableColumnType[]).includes(type);
  }

  isTextLikeType(type: DataTableColumnType): type is DataTableTextLikeColumnType {
    return (TEXT_LIKE_TYPES as DataTableColumnType[]).includes(type);
  }

  createColumn(
    columns: DataTableColumn[],
    type: DataTableColumnType = 'short-text',
    relation?: Partial<DataTableColumnRelationRef>,
  ): DataTableColumn {
    // We can only create one column at a given moment
    const id: DataTableColumn['id'] = PENDING_CREATION_COL_ID;
    const title: DataTableColumn['title'] = `Column ${this.getLastUsedNameIndex(columns) + 1}`;

    switch (type) {
      case 'relation':
        return {id, type, title, relation: {table_id: relation?.table_id ?? '', field_id: relation?.field_id ?? ''}};
      default:
        return {id, type, title};
    }
  }

  createRow(columns: DataTableColumn[], rowId = `${PENDING_CREATION_ROW_ID_PREFIX}_${uuid()}`): DataTableRow {
    const defaultValues = columns
      .filter(column => !column.read_only)
      .reduce(
        (row, column) => {
          row[column.id] = this.isEmptyValue(column.default_value) ? null : column.default_value;

          return row;
        },
        {} as Omit<DataTableRow, typeof ROW_ID_KEY>,
      );

    return {
      // We can only create one row at a given moment
      [ROW_ID_KEY]: rowId,
      ...defaultValues,
    };
  }

  validateRow(row: DataTableRow, columns: DataTableColumn[]): DataTableRowErrors | null {
    const errors: DataTableRowErrors = {};

    columns.forEach(column => {
      const error = this.validateValue(row[column.id], column);

      if (error) {
        errors[column.id] = error;
      }
    });

    return isEmpty(errors) ? null : errors;
  }

  // This method validates row values before they are saved, ensuring that only valid rows are saved.
  validateValue(value: DataTableCellValue, column: DataTableColumn): string | null {
    if (this.isEmptyValue(value)) {
      return column.required ? 'Input is required' : null;
    }

    switch (column.type) {
      case 'date':
        return !this.parseDateOrDateTime(value).isValid ? 'Invalid date format' : null;
      case 'date-time':
        return !this.parseDateOrDateTime(value).isValid ? 'Invalid datetime format' : null;
      case 'integer':
        return !this.isValidInteger(value) ? 'Value is not an integer' : null;
      case 'number':
        return !this.isValidNumber(value) ? 'Value is not a number' : null;
      case 'short-text':
      case 'long-text':
        return !this.isValidString(value, MAX_TEXT_LENGTH) ? `Value cannot exceed ${MAX_TEXT_LENGTH} symbols` : null;
      case 'relation':
        return !this.isValidRelation(value) ? 'Record doesn’t exist in linked table' : null;
      default:
        return null;
    }
  }

  validateDefaultValue(value: DataTableCellValue, type: DataTableColumnType, required?: boolean): string | null {
    if (this.isEmptyValue(value)) {
      return required ? 'Required column must have a default value' : null;
    }

    switch (type) {
      case 'integer':
        return !this.isValidInteger(value) ? 'Input must be a valid integer' : null;
      case 'number':
        return !this.isValidNumber(value) ? 'Input must be a valid number' : null;
      case 'short-text':
      case 'long-text':
        return !this.isValidString(value, MAX_DEFAULT_LENGTH)
          ? `Input cannot exceed ${MAX_DEFAULT_LENGTH} symbols`
          : null;
      default:
        return null;
    }
  }

  // This method validates persisted(previously saved) values, which might have become invalid because of external event.
  validatePersistedValue(value: DataTableCellValue, column: DataTableColumn): string | null {
    switch (column.type) {
      case 'relation':
        return value && !this.isValidRelation(value) ? 'Record doesn’t exist in linked table' : null;
      default:
        return null;
    }
  }

  formatValue(
    value: DataTableCellValue,
    type: DataTableColumnType,
    config: Pick<DataTableConfiguration, 'emptyValueFormat' | 'valueFormatters'> | null = null,
  ): string {
    if (this.isEmptyValue(value)) {
      return config?.emptyValueFormat ?? '<null>';
    }

    if (config?.valueFormatters && type in config.valueFormatters) {
      return config.valueFormatters[type]!(value);
    }

    switch (type) {
      case 'number':
      case 'integer':
        return (value as number).toString();
      case 'date':
        const date = this.parseDateOrDateTime(value);

        return date.isValid ? date.toFormat(this.dateFormat.inputDate) : (value as string);
      case 'date-time':
        const dateTime = this.parseDateOrDateTime(value);

        return dateTime.isValid ? dateTime.toFormat(this.dateFormat.inputDateTime) : (value as string);
      case 'boolean':
        return (value as boolean).toString().toUpperCase();
      case 'short-text':
      case 'long-text':
        return value as string;
      case 'file':
        return (value as DataTableFile).filename;
      case 'relation':
        return this.isValidRelation(value) ? value.value : INVALID_RELATION_TEXT;
    }
  }

  getInitialDefaultValueFor(type: DataTableColumnType): DataTableColumn['default_value'] {
    switch (type) {
      case 'date':
        return DateTime.fromMillis(0, {zone: 'utc'}).toISODate();
      case 'date-time':
        return DateTime.fromMillis(0, {zone: 'utc'}).toISO();
      default:
        return null;
    }
  }

  // This ensures that only full ISO date or date-time is successfully parsed (it must include date component)
  parseDateOrDateTime(value: DataTableCellValue): DateTime {
    if (typeof value !== 'string') {
      return DateTime.invalid('Not an ISO DateTime string');
    }

    if (!DateTime.fromFormat(value.slice(0, 10), 'yyyy-MM-dd').isValid) {
      return DateTime.invalid('ISO DateTime string does not include Date component');
    }

    return DateTime.fromISO(value);
  }

  convertNumericValue<TValue extends DataTableCellValue>(value: TValue, type: DataTableColumnType): TValue | number {
    const number = Number(value);

    return this.isNumberLikeType(type) && typeof value === 'string' && !this.isEmptyValue(value) && !isNaN(number)
      ? number
      : value;
  }

  convertNumericValues(row: DataTableRow, columns: DataTableColumn[]): DataTableRow {
    const result = {...row};

    columns
      .filter(column => !column.read_only)
      .forEach(column => {
        if (!this.isEmptyValue(result[column.id])) {
          result[column.id] = this.convertNumericValue(result[column.id], column.type);
        }
      });

    return result;
  }

  private getLastUsedNameIndex(columns: DataTableColumn[]): number {
    const usedNameIndices = columns
      .map(({title}) => DEFAULT_COLUMN_NAME_REGEX.exec(title)?.[1])
      .filter(nonNullable)
      .map(Number)
      .sort((a, b) => a - b);

    return last(usedNameIndices) || 0;
  }

  private isValidInteger(value: DataTableCellValue): boolean {
    if (typeof value !== 'string' && typeof value !== 'number') {
      return false;
    }

    if (value === '') {
      return false;
    }

    return Number.isInteger(Number(value));
  }

  private isValidNumber(value: DataTableCellValue): boolean {
    if (typeof value !== 'string' && typeof value !== 'number') {
      return false;
    }

    if (value === '') {
      return false;
    }

    return !Number.isNaN(Number(value));
  }

  private isValidString(value: DataTableCellValue, maxLength: number): boolean {
    /*
     * We display error if value length exceeds limit, but for some chars (ex. Japanese) String.length returns number of chars * 2
     * Array.length returns just the number of chars
     */
    return Array.from(value as string).length <= maxLength;
  }

  private isValidRelation(value: DataTableCellValue): value is DataTableValidRelation {
    return Boolean(
      isPlainObject(value) &&
        !(value as DataTableInvalidRelation).invalid &&
        (value as DataTableValidRelation).record_id,
    );
  }
}
