import {animate, animateChild, group, query, state, style, transition, trigger} from '@angular/animations';
import _ from 'lodash';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import {subscriptions} from '@shared/services/subscriptions';
import {LayoutService} from '@shared/services/layout.service';

import {appSettings} from '../../../app-settings';
import {AuthUser, AvailableTool} from '../../../services/auth-user';
import {OemService} from '../../oem/oem.service';
import {SIDEBAR_TOGGLE_DELAY, SidebarService} from '../sidebar.service';
import {SidebarSubMenuItem} from '../sidebar.types';
import {AccountEnvironment, AccountEnvironmentService} from '../../../services/account-environments.service';
import {isTBPV2MembershipPlan} from '../../usage-dashboard/usage-dashboard.helpers';
import {AuthUserPrivilegeExpression} from '../../../services/auth-user.types';

interface SidebarHelpLink {
  name: string;
  href: string;
}

enum SidebarState {
  OPENED = 'opened',
  CLOSED = 'closed',
  TEAM_SWITCHER = 'team-switcher',
  OPENED_COMPACT = 'opened-compact',
  CLOSED_COMPACT = 'closed-compact',
}

enum SidebarCompactHeaderState {
  OPENED = 'opened',
  CLOSED = 'closed',
}

// Don't forget to update width in styles.
const CLOSED_WIDTH = 88;
const OPENED_WIDTH = 296;
const TEAM_SWITCHER_WIDTH = 360;
const OPENED_WIDTH_COMPACT = 280;
const CLOSED_WIDTH_COMPACT = 0;

const OEM_AC_READ_PRIVILEGES: AuthUserPrivilegeExpression = {
  operator: 'or',
  operands: ['oem_ac_analytics.read', 'oem_ac_customers.read', 'oem_ac_automations.read'],
};

@Component({
  selector: 'w-sidebar',
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./sidebar.component.scss', './sidebar-compact-header.scss', './sidebar-overlay.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeOutContent', [
      transition(':leave', [
        style({opacity: 1, transform: 'translateX(0px)'}),
        animate('.2s cubic-bezier(.45, .05, .35, .95)', style({opacity: 0, transform: 'translateX(-8px)'})),
      ]),
    ]),
    trigger('slideInOut', [
      state(SidebarState.OPENED, style({width: OPENED_WIDTH})),
      state(SidebarState.CLOSED, style({width: CLOSED_WIDTH})),
      state(SidebarState.TEAM_SWITCHER, style({width: TEAM_SWITCHER_WIDTH})),
      state(SidebarState.CLOSED_COMPACT, style({width: CLOSED_WIDTH_COMPACT})),
      state(SidebarState.OPENED_COMPACT, style({width: OPENED_WIDTH_COMPACT})),
      transition('* => *', [
        group([animate('.2s cubic-bezier(.45, .05, .35, .95)'), query('@*', [animateChild()], {optional: true})]),
      ]),
    ]),
    trigger('fadeInOutOverlay', [
      transition(':enter', [style({opacity: 0}), animate('.2s cubic-bezier(.45, .05, .35, .95)')]),
      transition(':leave', [animate('.2s cubic-bezier(.45, .05, .35, .95)', style({opacity: 0.75}))]),
    ]),
    trigger('slideInOutCompactHeader', [
      state(SidebarCompactHeaderState.OPENED, style({left: -OPENED_WIDTH_COMPACT, right: OPENED_WIDTH_COMPACT})),
      state(SidebarCompactHeaderState.CLOSED, style({left: 0, right: 0})),
      transition('* => *', [animate('.2s cubic-bezier(.45, .05, .35, .95)')]),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  isCompact: boolean;

  toolsSubmenu: SidebarSubMenuItem[] = [];
  adminSubmenu: SidebarSubMenuItem[] = [];
  platformSubmenu: SidebarSubMenuItem[] = [];

  workspaceAdminTool?: AvailableTool;

  helpLinks: SidebarHelpLink[];
  animationInProgress = false;

  oemFooterPositionLeft: number | null = null;

  isTeamSwitcherOpened = false;
  isSidebarOpened = false;

  private timer: number | null = null;
  private subs = subscriptions();

  constructor(
    private authUser: AuthUser,
    private oemService: OemService,
    private sidebarService: SidebarService,
    private layoutService: LayoutService,
    private elementRef: ElementRef,
    private cd: ChangeDetectorRef,
    private accountEnvironments: AccountEnvironmentService,
  ) {}

  ngOnInit() {
    if (!this.isAppUser) {
      this.initTools();
      this.initAdminSubmenu();
    }

    this.initHelpLinks();

    this.subs.add(
      this.layoutService.change.subscribe(() => {
        this.isCompact = this.layoutService.isCompactLayout;

        this.cd.markForCheck();
      }),
      this.sidebarService.isTeamSwitcherOpened$.subscribe(isOpened => {
        this.isTeamSwitcherOpened = isOpened;
        this.cd.markForCheck();
      }),
      this.sidebarService.isSidebarOpened$.subscribe(isOpened => {
        this.isSidebarOpened = isOpened;
        this.cd.markForCheck();
      }),
    );
  }

  get userName(): string {
    return this.authUser.logged_name;
  }

  get userAvatar(): string {
    return this.authUser.profile_image;
  }

  get projectsHeaderItemName(): string {
    return this.oemService.mixedAssets?.navigation_name ?? 'Projects';
  }

  get isWhitelabel(): boolean {
    return this.oemService.isWhitelabel;
  }

  get oemLogo(): string {
    return this.oemService.appLogo;
  }

  get oemLogoSmall(): string {
    return this.oemService.appLogoSmall;
  }

  get oemCompany(): string {
    return this.oemService.appName;
  }

  get hasTeamSwitch(): boolean {
    return (
      !(this.isOem && this.oemService.teamSwitchingDisabled) &&
      !this.isAppUser &&
      (this.hasTeams || this.hasEnvironments)
    );
  }

  get isAppUser(): boolean {
    return this.authUser.app_user;
  }

  get hasPlatformItems(): boolean {
    return Boolean(this.platformSubmenu.length);
  }

  get hasTools(): boolean {
    return Boolean(this.toolsSubmenu.length || this.hasUnavailableTools);
  }

  get isTeamSession(): boolean {
    return this.authUser.team_session;
  }

  get hasUnavailableTools(): boolean {
    return !this.isTeamSession && this.authUser.has_unavailable_tools;
  }

  get isOem(): boolean {
    return this.oemService.themeEnabled;
  }

  get hasTeams(): boolean {
    return Boolean(this.authUser.availableTeams?.length);
  }

  get hasEnvironments(): boolean {
    return Boolean(this.accountEnvironments.available.length);
  }

  get hasCommunityAccess(): boolean {
    return !this.oemService.communityNavItemHidden;
  }

  get hasOemAdminConsole(): boolean {
    return (
      this.authUser.hasPrivilege(OEM_AC_READ_PRIVILEGES) ||
      (this.authUser.hasPrivilege(['oem_ac_analytics.manage']) &&
        (this.authUser.hasPrivilege('oem_vendor') || this.authUser.hasPrivilege('audit_log_replication')))
    );
  }

  get slideAnimationState(): SidebarState {
    if (this.isCompact) {
      return this.sidebarService.isSidebarOpened ? SidebarState.OPENED_COMPACT : SidebarState.CLOSED_COMPACT;
    } else if (this.sidebarService.isTeamSwitcherOpened) {
      return SidebarState.TEAM_SWITCHER;
    } else if (this.sidebarService.isSidebarOpened) {
      return SidebarState.OPENED;
    }

    return SidebarState.CLOSED;
  }

  get slideAnimationStateCompactHeader(): SidebarCompactHeaderState {
    return this.sidebarService.isSidebarOpened ? SidebarCompactHeaderState.OPENED : SidebarCompactHeaderState.CLOSED;
  }

  get logoLinkUrl(): string {
    return '/?fid=projects';
  }

  get element(): Element {
    return this.elementRef.nativeElement;
  }

  get currentEnvironment(): AccountEnvironment | null {
    return this.accountEnvironments.current;
  }

  get hasUsageDashboardAccess(): boolean {
    return isTBPV2MembershipPlan(this.authUser);
  }

  @HostListener('mouseenter')
  @HostListener('focusin')
  openSidebar() {
    if (this.animationInProgress || this.isCompact) {
      return;
    }

    this.stopTimer();

    this.timer = setTimeout(() => {
      this.sidebarService.openSidebar();
    }, SIDEBAR_TOGGLE_DELAY);
  }

  @HostListener('mouseleave')
  @HostListener('focusout')
  closeSidebar() {
    if (this.isCompact) {
      return;
    }

    this.stopTimer();

    this.timer = setTimeout(() => {
      if (!this.isTeamSwitcherOpened && !this.sidebarService.isSubmenuOpened) {
        this.sidebarService.hideSidebar();
      }
    }, SIDEBAR_TOGGLE_DELAY);
  }

  handleOemLogoIconLoad(event: Event) {
    const iconWidth = (event.target as HTMLElement).offsetWidth;

    // Alignment to center wide or narrow oem logo-icon
    this.oemFooterPositionLeft = CLOSED_WIDTH / 2 - iconWidth / 2;
  }

  handleBurgerButtonClick() {
    if (this.isSidebarOpened) {
      this.sidebarService.hideSidebar();
    } else {
      this.sidebarService.openSidebar();
    }
  }

  hideSidebar() {
    this.stopTimer();

    this.sidebarService.hideSidebar();
  }

  private initTools() {
    const availableTools = [...this.authUser.available_tools];
    const platformKeys: Array<AvailableTool['id']> = [
      'service_console',
      'topic',
      'workbot',
      'lcap_portal',
      'usage_insights',
    ];

    this.workspaceAdminTool = _.remove(availableTools, tool => tool.id === 'team')[0];

    availableTools.forEach(tool => {
      const toolView: SidebarSubMenuItem = {
        type: 'link',
        name: tool.name,
        iconId: tool.id,
        routerUrl: {path: tool.href},
      };

      if (platformKeys.includes(tool.id)) {
        this.platformSubmenu.push(toolView);
      } else {
        this.toolsSubmenu.push(toolView);
      }
    });

    this.platformSubmenu.sort(this.compareSubmenuItems);
    this.toolsSubmenu.sort(this.compareSubmenuItems);

    if (this.hasUnavailableTools && !this.accountEnvironments.isSecondary) {
      const toolsMenuEmpty = !this.toolsSubmenu.length;

      if (!toolsMenuEmpty) {
        this.toolsSubmenu.push({type: 'separator'});
      }

      this.toolsSubmenu.push({
        type: 'link',
        routerUrl: {path: '/tools'},
        name: 'Discover more tools',
        iconId: toolsMenuEmpty ? 'tools' : undefined,
      });
    }
  }

  private initAdminSubmenu() {
    this.adminSubmenu = [];

    if (this.authUser.hasRole('integration_app_admin')) {
      this.adminSubmenu.push({
        type: 'link',
        routerUrl: {path: '/integration_apps'},
        name: 'Manage apps',
        iconId: 'manage_apps',
      });
    }

    if (this.authUser.hasRole('content_admin')) {
      this.adminSubmenu.push({
        type: 'link',
        routerUrl: {path: '/unsupported_connectors'},
        name: 'Manage unsupported connectors',
        iconId: 'unsupported_connectors',
      });
    }

    if (this.authUser.hasRole('adapter_prospects_admin')) {
      this.adminSubmenu.push({
        type: 'link',
        routerUrl: {path: '/community_admin'},
        name: 'SDK Community admin',
        iconId: 'sdk_community_admin',
      });
    }

    this.adminSubmenu.sort(this.compareSubmenuItems);
  }

  private initHelpLinks() {
    if (!this.isOem) {
      this.helpLinks = [
        {
          name: 'Privacy',
          href: `${appSettings.websiteUrl}/legal/privacy-policy`,
        },
        {
          name: 'Terms',
          href: `${appSettings.websiteUrl}/legal/terms-of-service`,
        },
        {
          name: 'Partners',
          href: `${appSettings.websiteUrl}/partners`,
        },
        {
          name: 'Docs',
          href: 'https://docs.workato.com',
        },
      ];
    } else {
      this.helpLinks = [];

      if (this.oemService.footerLinks?.privacy_url) {
        this.helpLinks.push({
          name: 'Privacy',
          href: this.oemService.footerLinks.privacy_url,
        });
      }

      if (this.oemService.footerLinks?.terms_url) {
        this.helpLinks.push({
          name: 'Terms',
          href: this.oemService.footerLinks.terms_url,
        });
      }

      if (this.oemService.footerLinks?.support) {
        this.helpLinks.push({
          name: 'Support',
          href: this.oemService.footerLinks.support,
        });
      }
    }
  }

  private stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private compareSubmenuItems = (a: SidebarSubMenuItem, b: SidebarSubMenuItem): number =>
    a.type === 'link' && a.name && b.type === 'link' && b.name ? a.name.localeCompare(b.name) : 0;
}
