import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {Mixpanel} from '../../services/mixpanel';

import {AppVersionService} from './app-version.service';

export const appVersionUpdateGuard = (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const appVersionService = inject(AppVersionService);

  if (appVersionService.outdated) {
    if (!appVersionService.guardUpdating) {
      appVersionService.guardUpdating = true;
      inject(Mixpanel).track(
        'Version sync: updating version',
        {
          to: appVersionService.serverVersion,
          timePassed: appVersionService.timePassedSinceInitialVersionChange,
        },
        () => {
          location.href = state.url;
        },
      );
    }

    return false;
  }

  return true;
};
