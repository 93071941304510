import {Component, Injector, ViewEncapsulation} from '@angular/core';

import {AbstractDialogComponent} from '@shared/modules/dialog/abstract-dialog.component';
import {DIALOG_ARIA_LABEL_PROVIDER} from '@shared/modules/dialog/dialog.tokens';

export {DialogContentComponent} from '@shared/modules/dialog/dialog.types';

@Component({
  selector: 'w-dialog',
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [DIALOG_ARIA_LABEL_PROVIDER],
})
export class DialogComponent extends AbstractDialogComponent {
  constructor(public injector: Injector) {
    super();
  }
}
