import _ from 'lodash';
import {BehaviorSubject, Observable, Subject, firstValueFrom, map, merge, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {delay, filter, skip, switchMap, takeWhile, tap} from 'rxjs/operators';

import {RequiredProps} from '@shared/types/lib';
import {HttpResource, Request, RequestError, getRequestAsObservable} from '@shared/services/http-resource';

import {
  FieldsInput,
  Job,
  Recipe,
  RecipeCodeError,
  RecipeData,
  RecipeId,
  RecipeState,
  RecipeStep,
  RedirectedResponse,
} from '../types';
import {Folder} from '../modules/folders/folders.types';
import {Workbot} from '../pages/workbots/workbots.types';
import {IntegrationApp, RecipeIa} from '../pages/integration-apps/integration-apps.types';
import {makeRedirectedRequest} from '../utils/make-redirected-request';
import {JobHistoryUrlParams} from '../modules/job-history/job-history-params.service';
import {JobDetailsService} from '../pages/job-details/job-details.service';
import {ConnectionConfig} from '../pages/connections/connections.types';

import {RecipeProblemsData} from './recipe-problems/recipe-problems.types';
import {RootDialogService} from './root-dialog.service';
import {AuthUser} from './auth-user';
import {StepHelper} from './step-helper.service';
import {StepTraverser} from './step-traverser.service';

export interface JobsRequestParams extends JobHistoryUrlParams {
  id: number;
  per_page?: number;
  reruns_only?: boolean;
  test_jobs_only?: boolean;
  test_case_jobs_only?: boolean;
  avoid_cache?: boolean;
}

export interface JobsResponse {
  success: boolean;
  job_count: number;
  job_scope_count: number;
  job_succeeded_count: number;
  job_failed_count: number;
  job_per_page: number;
  job_offset_count?: number;
  jobs: Job[];
}

export interface RecipeCursorSummary {
  description: string;
  status: string;
  stop_recipe_hint: string;
}
export interface RecipeQueueStats {
  queue_estimated_size: number;
  queue_estimated_clearing_time_sec: number;
  job_succeeded_count: number;
  job_failed_count: number;
  job_count: number;
}

export interface JobsCounters {
  recipeId: Recipe['id'];
  total: number;
  succeeded: number;
  failed: number;
}

interface RecipeTriggerEventErrorDetails {
  error: {
    error: {
      trigger_event_errors: RecipeCodeError[];
    };
  };
}

export type RecipeTriggerEventErrorResponse = RequiredProps<RequestError<RecipeTriggerEventErrorDetails>, 'details'>;

export interface RecipeActivityResponse {
  success: boolean;
  flow: {
    id: Recipe['id'];
    running: boolean;
    testing: boolean;
    last_run_at?: Recipe['last_run_at'];
    stopped_at?: Recipe['stopped_at'];
    last_actionable_error?: Recipe['last_actionable_error'];
    state: RecipeState;
  };
}

export interface RecipeStateResponse {
  state: RecipeState;
  error: object | null;
}

export interface RecipeGroupOperationResult {
  recipes: {
    [id: number]: {success: true; flow: Recipe} | {error: {details: RecipeProblemsData}};
  };
}

export interface CopyRecipeParams {
  st?: string;
  community?: string;
  folder_id?: Folder['id'];
}

export type LastRunType = 'start' | 'test' | 'prepare-test' | 'repeat' | null;

interface GetRecipeParams {
  id: Recipe['id'] | null;
  version_no?: Recipe['version_no'];
  st?: string;
  workbot?: Workbot['id'];
  app_id?: IntegrationApp['id'];
  community?: string;
}

export interface GetRecipeResponse {
  recipe_data: RecipeData;
  workbot_path?: string;
  is_ia?: boolean;
}

interface RecipeBuildOptions extends Partial<Omit<Recipe, 'code'>> {
  code?: RecipeStep;
}

export interface RecipeVersion {
  version_no: number;
  major_version_no: number;
  created_at: string;
  user_name: string | null;
  comment: string | null;
}

export interface RecipeVersionsFetchParams {
  id: number;
  offset: number;
  limit: number;
  query?: string;
  created_at?: string | null;
  created_at_from?: string | null;
  created_at_to?: string | null;
  change_type?: string | null;
  collaborator_id?: number | null;
}

export interface RecipeVersionAuthor {
  id: number;
  name: string;
}

export interface MultipleJobsOperationResponse {
  error?: string;
  result: {
    [jobHandle: string]: {
      result?: true;
      error?: string;
    };
  };
}

export interface RecipeCompareResult {
  v1: {
    code: Recipe['code'];
    connection_config: ConnectionConfig[];
  };
  v2: {
    code: Recipe['code'];
    connection_config: ConnectionConfig[];
  };
}

const POLLING_DELAYS = [0.5, 0.5, 1, 1, 2, 2, 5, 5, 10] as const;

@Injectable({
  providedIn: 'root',
})
export class RecipeService {
  saved$: Observable<void>;
  started$: Observable<void>;
  stopped$: Observable<void>;
  testStarted$: Observable<void>;
  testStopped$: Observable<void>;
  running$: Observable<{id: Recipe['id']; running: boolean}>;
  state$: Observable<{id: Recipe['id']; state: RecipeState}>;

  editingAllowed: boolean;
  lastRunType: LastRunType = null;
  jobsCountersUpdated$ = new Subject<JobsCounters>();

  recipes: HttpResource;
  recipesCompare: HttpResource;
  webApiRecipes: HttpResource;
  webApiQueueStats: HttpResource;
  webApiTriggerState: HttpResource;

  private saved = new Subject<void>();
  private started = new Subject<void>();
  private stopped = new Subject<void>();
  private testStarted = new Subject<void>();
  private testStopped = new Subject<void>();
  private state = new Subject<{id: Recipe['id']; state: RecipeState; testing: Recipe['testing']}>();

  constructor(
    private authUser: AuthUser,
    private http: HttpClient,
    private dialog: RootDialogService,
    private stepHelper: StepHelper,
    private stepTraverser: StepTraverser,
    private jobDetails: JobDetailsService,
  ) {
    this.editingAllowed = this.authUser.hasPrivilege('recipe.update');
    this.recipes = new HttpResource(this.http, {
      url: '/recipes/{{id}}/{{action}}/{{version}}.json',
    });
    this.recipesCompare = new HttpResource(this.http, {
      url: '/recipes/compare',
    });
    this.webApiRecipes = new HttpResource(this.http, {
      url: '/web_api/recipes/{{id}}/{{action}}/{{version}}.json',
    });
    this.webApiQueueStats = new HttpResource(this.http, {
      url: '/web_api/recipes/{{id}}/queue_stats',
    });
    this.webApiTriggerState = new HttpResource(this.http, {
      url: '/web_api/recipes/{{id}}/trigger_state',
    });

    this.saved$ = this.saved.asObservable();
    this.started$ = this.started.asObservable();
    this.stopped$ = this.stopped.asObservable();
    this.testStarted$ = this.testStarted.asObservable();
    this.testStopped$ = this.testStopped.asObservable();
    this.state$ = this.state.asObservable();
    this.running$ = this.state.pipe(
      map(state => ({id: state.id, running: this.isRunningState(state.state, state.testing)})),
    );
  }

  setRecipeState(recipe: Recipe | RecipeIa, state: RecipeState, testing = recipe.testing): void {
    recipe.testing = testing;
    recipe.running = this.isRunningState(state, testing);
    recipe.state = state;

    this.state.next({id: recipe.id, state, testing});
  }

  reset() {
    this.lastRunType = null;
  }

  isActive(recipe: Pick<Recipe, 'running'>): boolean {
    return Boolean(recipe.running);
  }

  isDeleted(recipe: Pick<Recipe, 'deleted_at'>): boolean {
    return Boolean(recipe.deleted_at);
  }

  build(opts: RecipeBuildOptions = {}): Omit<Recipe, 'id'> {
    // eslint-disable-next-line prefer-const
    let {code, ...otherProps} = opts;

    if (code) {
      code = _.cloneDeep(code);
      code.block = code.block || [];
    } else {
      code = {
        keyword: 'trigger',
        number: 0,
        block: [
          {
            keyword: 'action',
            number: 1,
          },
        ],
      };
    }

    this.stepHelper.buildIdentifiersFor(code).forEach(({step: affectedStep, newIdentifiers}) => {
      Object.assign(affectedStep, newIdentifiers);
    });

    this.stepTraverser.walk(code, step => {
      step.input = step.input || {};
    });

    const triggerApplication = code.provider!;
    const actionApplications = this.stepHelper.getActionApplications(code);

    return {
      name: '',
      description: '',
      trigger_application: triggerApplication,
      action_applications: actionApplications,
      applications: _.uniq([triggerApplication, ...actionApplications]),
      user_id: this.authUser.id,
      user_name: this.authUser.name,
      code: JSON.stringify(code),
      config: [],
      copy_count: 0,
      job_failed_count: 0,
      job_succeeded_count: 0,
      worker_concurrency: 1,
      visibility_private: this.authUser.private_recipes.default,
      ...otherProps,
    };
  }

  queueStats(id: Recipe['id']): Request<RecipeQueueStats> {
    return this.webApiQueueStats.get({id});
  }

  getTriggerState(id: Recipe['id']): Request<RecipeCursorSummary | null> {
    return this.webApiTriggerState.get({id});
  }

  jobs(params: JobsRequestParams): Request<JobsResponse> {
    let request: Request<JobsResponse>;
    const {id, repeated_jobs_by_master_job_id, avoid_cache, ...otherParams} = params;

    if (repeated_jobs_by_master_job_id) {
      const {offset_job_id, offset_count, per_page, prev} = otherParams;

      request = this.jobDetails.getRepeats(id, repeated_jobs_by_master_job_id, {
        offset_job_id,
        offset_count,
        per_page,
        prev,
        repeat: avoid_cache,
      });
    } else {
      request = this.webApiRecipes.get({id, action: 'jobs'}, {query: {...otherParams, repeat: avoid_cache}});
    }

    const result: Promise<JobsResponse> = request.then(response => {
      this.jobsCountersUpdated$.next({
        recipeId: id,
        total: response.job_count,
        succeeded: response.job_succeeded_count,
        failed: response.job_failed_count,
      });

      return response;
    });

    (result as Request<JobsResponse>).abort = request.abort;

    return result as Request<JobsResponse>;
  }

  repeatJobs(params: {
    id: number;
    master_job_ids: Array<Job['master_job_id']>;
  }): Request<MultipleJobsOperationResponse> {
    this.lastRunType = 'repeat';

    return this.webApiRecipes.post({id: params.id, action: 'repeat_jobs'}, params);
  }

  cancelJobs(params: {id: number; job_ids: Array<Job['id']>}): Request<MultipleJobsOperationResponse> {
    return this.webApiRecipes.post({id: params.id, action: 'cancel_jobs'}, params);
  }

  dismissUpgradeNotice(params: {id: number}): Request<{success: boolean}> {
    return this.recipes.put({id: params.id, action: 'upgrade_notice_mark_as_seen'}, params);
  }

  restoreVersion(params: {id: number; version_no: number}): Request<{success: boolean}> {
    return this.recipes.put({id: params.id, action: 'restore_version'}, params);
  }

  upgradeVersion(params: {id: number}): Request<{success: boolean}> {
    return this.recipes.put({id: params.id, action: 'upgrade_from_parent'}, params);
  }

  async fetchJobsCounters(id: RecipeId): Promise<JobsCounters> {
    const response = await this.jobs({id, per_page: 0, avoid_cache: true});

    return {
      recipeId: id,
      total: response.job_count,
      succeeded: response.job_succeeded_count,
      failed: response.job_failed_count,
    };
  }

  get(params: GetRecipeParams): Request<GetRecipeResponse> {
    return this.recipes.get({id: params.id}, {query: _.omit(params, 'id')});
  }

  getCode(params: Pick<GetRecipeParams, 'id' | 'version_no' | 'st' | 'community'>): Request<Recipe['code']> {
    return this.recipes.get({id: params.id, action: 'code'}, {query: _.omit(params, 'id')});
  }

  getRecipesCompareResult(recipeId: number, query: {v1: string; v2: string}): Request<RecipeCompareResult> {
    return this.recipesCompare.getAll({
      query,
    });
  }

  getConnectionConfig(params: Pick<GetRecipeParams, 'id' | 'version_no'>): Request<ConnectionConfig[]> {
    return this.recipes.get({id: params.id, action: 'connection_config'}, {query: _.omit(params, 'id')});
  }

  status(params: {recipe: Recipe | RecipeIa}): Request<RecipeActivityResponse> {
    return this.recipes.get({id: params.recipe.id, action: 'status'}, {query: params});
  }

  async create(recipe: Partial<Recipe>): Promise<RecipeData> {
    let response;

    try {
      response = await this.recipes.create({flow: recipe}, {compress: true});
    } catch (err) {
      throw this.processError(err);
    }

    this.saved.next();

    return response;
  }

  async update(id: Recipe['id'], recipeProps: Partial<Recipe>): Promise<RecipeData> {
    let response;

    try {
      response = await this.recipes.put(
        {id},
        {
          flow: recipeProps,
          client_uuid: this.authUser.clientSessionId,
        },
        {compress: true},
      );
    } catch (err) {
      throw this.processError(err);
    }

    this.saved.next();

    return response;
  }

  async copy(id: number, data?: CopyRecipeParams): Promise<RedirectedResponse> {
    return makeRedirectedRequest(this.http.post(this.recipes.url({id, action: 'copy'}), data, {observe: 'response'}));
  }

  // Moves recipe to Trash
  delete(id: number): Request<RecipeData> {
    return this.recipes.delete({id});
  }

  // Permanently deletes recipes from Trash folder.
  deletePermanently(ids: Array<Recipe['id']> | 'all'): Promise<void> {
    return this.recipes.post({action: 'delete_permanently'}, {ids});
  }

  // Restores recipes from Trash folder.
  restore(ids: Array<Recipe['id']>, targetFolderId?: Folder['id']): Promise<void> {
    return this.recipes.post({action: 'undelete'}, {ids, folder_id: targetFolderId});
  }

  pollNow(params: {id: Recipe['id']}): Request<{success: boolean}> {
    return this.recipes.put({id: params.id, action: 'poll_now'}, params);
  }

  prepareTest() {
    this.lastRunType = 'prepare-test';
  }

  async test(params: {id: number}): Promise<RecipeActivityResponse> {
    this.lastRunType = 'test';

    const response = await this.recipes.put({id: params.id, action: 'test'}, params);

    this.testStarted.next();

    return response;
  }

  async start(params: {recipe: Recipe | RecipeIa; refresh_schema?: boolean}): Promise<void> {
    this.lastRunType = 'start';
    await this.optimisticallyUpdateState('activating', params.recipe, async () => {
      await this.webApiRecipes.post({id: params.recipe.id, action: 'start'}, {refresh_schema: params.refresh_schema});

      return this.waitForState(params.recipe, 'running');
    });
    this.started.next();
  }

  async startWithStatus(params: {
    recipe: Recipe | RecipeIa;
    refresh_schema?: boolean;
  }): Promise<RecipeActivityResponse> {
    await this.start(params);

    return this.status(params);
  }

  async stop(params: {recipe: Recipe | RecipeIa; force?: boolean}): Promise<boolean> {
    await this.optimisticallyUpdateState('deactivating', params.recipe, async () => {
      try {
        await this.webApiRecipes.post({id: params.recipe.id, action: 'stop'}, {force: params.force});
        await this.waitForState(params.recipe, 'stopped');
      } catch (error) {
        const err = this.processError(error);
        const hasDetails =
          err.details?.activeEndpointsCount ||
          err.details?.pendingJobsQueueCount ||
          err.details?.activeDependentRecipesCount ||
          err.details?.triggerStopRecipeHint;

        if (!hasDetails) {
          throw err;
        }

        if (
          await this.showStopModalConfirmation(
            err.details.activeEndpointsCount,
            err.details.pendingJobsQueueCount,
            err.details.activeDependentRecipesCount,
            err.details.triggerStopRecipeHint,
          )
        ) {
          return this.stop({...params, force: true});
        } else {
          // If user close confirmation popup we shouldn't show error
          return false;
        }
      }
    });

    this.stopped.next();

    return true;
  }

  async stopWithStatus(params: {recipe: Recipe | RecipeIa; force?: boolean}): Promise<RecipeActivityResponse | null> {
    if (!(await this.stop(params))) {
      return null;
    }

    return this.status(params);
  }

  async stopTest(id: Recipe['id']): Promise<true> {
    const response = await this.recipes.put({id, action: 'stop_test'});

    this.testStopped.next();

    return response;
  }

  paramUpdate(id: Recipe['id'], params: FieldsInput): Request<void> {
    return this.recipes.put(
      {id, action: 'param_update'},
      {
        id,
        flow: {
          param: params,
        },
      },
    );
  }

  startGroup(ids: Array<Recipe['id']>, opts: {includeAllErrors: boolean}): Request<RecipeGroupOperationResult> {
    return this.recipes.put(
      {action: 'start_group'},
      {
        ids,
        include_all_errors: opts?.includeAllErrors,
      },
    );
  }

  versions(
    params: RecipeVersionsFetchParams,
  ): Promise<{versions: RecipeVersion[]; count: number; total_count: number}> {
    return this.recipes.get({id: params.id, action: 'versions'}, {query: params});
  }

  versionsCollaborators(params: {id: number}): Promise<RecipeVersionAuthor[]> {
    return this.recipes.get({id: params.id, action: 'versions_collaborators'});
  }

  updateVersionComment(id: Recipe['id'], versionNumber: RecipeVersion['version_no'], comment: string): Request<void> {
    return this.recipes.put({id, action: 'versions', version: versionNumber}, {comment});
  }

  moveToFolder(recipeId: Recipe['id'], folderId: Folder['id']): Request<void> {
    return this.recipes.put({id: recipeId, action: 'update_folder'}, {folder_id: folderId});
  }

  async showStopModalConfirmation(
    activeEndpointsCount = 0,
    pendingJobsQueueCount = 0,
    activeDependentRecipesCount = 0,
    triggerStopRecipeHint: string | null,
  ): Promise<boolean> {
    if (
      !activeEndpointsCount &&
      !pendingJobsQueueCount &&
      !activeDependentRecipesCount &&
      triggerStopRecipeHint === null
    ) {
      return true;
    }

    const {RecipeStopConfirmMessageComponent} = await import(
      '../components/recipe-stop-confirm-message/recipe-stop-confirm-message.component'
    );

    return this.dialog.openConfirmationDialog('Are you sure?', RecipeStopConfirmMessageComponent, {
      confirmButton: 'Stop recipe',
      cancelButton: 'Cancel',
      contentInputs: {
        activeEndpointsCount,
        pendingJobsQueueCount,
        activeDependentRecipesCount,
        isProcessingTriggerEvent: Boolean(triggerStopRecipeHint),
      },
    });
  }

  async import(data: FormData): Promise<RedirectedResponse> {
    return makeRedirectedRequest(
      this.http.post(this.recipes.url({action: 'import_create'}), data, {observe: 'response'}),
    );
  }

  async changeConcurrency(id: Recipe['id'], workerConcurrency: Recipe['worker_concurrency']): Promise<void> {
    return this.webApiRecipes.post(
      {
        id,
        action: 'change_concurrency',
      },
      {
        worker_concurrency: workerConcurrency,
      },
    );
  }

  private processError(err: RequestError): RequestError {
    const activeEndpointsCount = err.details?.active_endpoints_count?.[0];
    const pendingJobsQueueCount = err.details?.jobs_queue_count?.[0];
    const activeDependentRecipesCount = err.details?.active_dependent_recipes_count?.[0];
    const triggerStopRecipeHint = err.details?.trigger_stop_recipe_hint?.[0] || null;

    if (activeEndpointsCount || pendingJobsQueueCount || activeDependentRecipesCount || triggerStopRecipeHint) {
      return new RequestError('Error', false, {
        activeEndpointsCount,
        pendingJobsQueueCount,
        activeDependentRecipesCount,
        triggerStopRecipeHint,
      });
    } else {
      return err;
    }
  }

  private async waitForState(recipe: Recipe | RecipeIa, state: RecipeStateResponse['state']): Promise<unknown> {
    const pollingAttempt = new BehaviorSubject<number>(0);
    const stateReachedViaPolling$ = pollingAttempt.pipe(
      switchMap(() => this.getStatePollingRequestObservable(recipe.id)),
      takeWhile(response => response.state !== state, true),
      switchMap(response =>
        response.state === state
          ? of(response)
          : of(response).pipe(
              delay(this.getPollingDelay(pollingAttempt.value)),
              tap(() => pollingAttempt.next(pollingAttempt.value + 1)),
              // This skip prevents observable from emitting value on unsuccessful poll
              skip(1),
            ),
      ),
    );

    const stateReachedExternally$ = this.state.pipe(
      filter(recipeState => recipeState.id === recipe.id && recipeState.state === state),
    );

    return firstValueFrom(merge(stateReachedViaPolling$, stateReachedExternally$));
  }

  private getStatePollingRequestObservable(id: number): Observable<RecipeStateResponse> {
    return getRequestAsObservable(this.webApiRecipes.get({id, action: 'state'}));
  }

  private getPollingDelay(attempt: number): number {
    return 1000 * (POLLING_DELAYS[attempt] ?? POLLING_DELAYS[POLLING_DELAYS.length - 1]);
  }

  private async optimisticallyUpdateState(
    state: RecipeState,
    recipe: Recipe | RecipeIa,
    action: () => Promise<unknown>,
  ) {
    const initialState = recipe.state;

    this.setRecipeState(recipe, state);

    try {
      await action();
    } catch (err) {
      this.setRecipeState(recipe, initialState || 'stopped');
      throw err;
    }
  }

  private isRunningState = (state: RecipeState, testing = false): boolean =>
    !testing && state !== 'stopped' && state !== 'deleted';
}
